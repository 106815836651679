import React from "react";
import { Button, Card, CardHeader, CardText, Carousel, CarouselItem, Collapse, ListGroup, ListGroupItem } from 'react-bootstrap';
// import react-redux
import { useDispatch } from "react-redux";
import { REMOVE_FROM_CART, UPDATE_CART_QUANTITY } from "./utils/actions";
import { idbPromise } from "./utils/helpers.js";

const CartItem = ({ item }) => {
  console.log(item)
  // create dispatch object from useDispatch()
  const dispatch = useDispatch();

  //! since the item is being passed in as a prop from cart component with "state.cart.map((item).... <CartItem item='item'/>" we dont need state
    // // create state object from useSelector()
      // const state = useSelector((s) => s);

  const removeFromCart = (item) => {
    dispatch({
      type: REMOVE_FROM_CART,
      _id: item._id,
      size: item.size
    });
    idbPromise("cart", "delete", { ...item });
  };

  const onChange = (e) => {
    const value = e.target.value;

    if (value === "0") {
      dispatch({
        type: REMOVE_FROM_CART,
        _id: item._id,
        size: item.size,
      });
      idbPromise("cart", "delete", { ...item });
    } else {
      dispatch({
        type: UPDATE_CART_QUANTITY,
        _id: item._id,
        size: item.size,
        purchaseQuantity: parseInt(value),
      });
      idbPromise("cart", "put", { ...item, purchaseQuantity: parseInt(value) });
    }
  };

  return (
    <div >
      {/* <div className='cart-item__image'>
        <img src={`/images/${item.image[0]}`} alt="" />
      </div> */}
      <Card className="custom-shop-border col-3"
        style={{
        width: '50%',
        height: '50%',
        marginTop: '2%',
        marginBottom: '1%',
        color:'white'
        }}>
        <Card.Body>
        <div className="justify-content-left">
        <div className="cart-item__name-price">
          <h3>
          {item.name}
          </h3>
        </div>
        <div className="cart-item__name-price">
          ${item.price}
        </div>
        <div className="cart-item__size" >
          Size :{item.size}
        </div>
        <div className="cart-item__quantity">
          <span>Qty:</span>
          <input
          style={{
            width: '50px',  // Set a specific width to control size
            padding: '2px', // Adjust padding to reduce inner space
            margin: '0 5px'
          }}
            type="number"
            placeholder="1"
            value={item.purchaseQuantity}
            onChange={onChange}
          />
          <span
          className="cart-item__delete"
            role="img"
            aria-label="trash"
            onClick={() => removeFromCart(item)}
          >
            🗑️
          </span>
        </div>
      </div>

</Card.Body>
      </Card>

    </div>
  );
};

export default CartItem;

