// src/context/AuthContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the context
const AuthContext = createContext();

// Provider component that wraps your app and provides the current user's data
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // You can replace this with real authentication logic

  // Example: function to set the logged-in user (for demo purposes)
  const login = (userData) => setUser(userData);
  const logout = () => setUser(null);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the authentication context
export const useAuth = () => useContext(AuthContext);
