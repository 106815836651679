import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card,CardText, ListGroup, ListGroupItem, Form} from 'react-bootstrap';
import { BiLogoImdb } from "react-icons/bi";
import AuthService from "../utils/auth";

function CrewProfile() {
  // console.log("CrewProfile component rendered"); 
  const { crewmember } = useParams();
  // console.log("Crewmember extracted from URL:", crewmember);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [originalData, setOriginalData] = useState({}); 
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedUnionType, setSelectedUnionType] = useState('');
  const [selectedUnion, setSelectedUnion] = useState('');
  const [selectedLocal, setSelectedLocal] = useState('');
  const [profilePreviews, setProfilePreviews] = useState([]);
  const [isResumeDeleted, setIsResumeDeleted] = useState(false);
  const [residencePreview, setResidencePreview] = useState(null);
  const [isResidenceDeleted, setIsResidenceDeleted] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [positionError, setPositionError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [unionTypeError, setUnionTypeError] = useState("");
  const [workRegionError, setWorkRegionError] = useState("");
  const [profileIError, setProfileIError] = useState("");
  const [residenceError, setResidenceError] = useState("");

  const [formState, setFormState] = useState({ 
    email: '', 
    password: '', 
    crewMember: '',
    phoneNumber: '',
    union: '',
    location: '',
    local: '',
    bio:'',
    iMDB: '',
    workRegion: [], 
    unionType: [],
    resumes: [{ title: '', year: '', position: '' }],
    resumeP: [],
    residence: [],
    position: [],
    department: [],
    profileI: [],  
   });

   const workRegion = ['Central', 'East', 'Dallas Fort Worth', 'Gulf Coast', 'Panhandle', 'West'];
   const positions = [
    'actor', 'producer', 'executive producer', 'co-executive producer',
    'showrunner', 'unit production manager', 'production coordinator', 'director', 
    'assistant director', '1st assistant director', '2nd assistant director', 
    'camera operator',  'key hair', 'key makeup', 'production accountant',
    '1st assistant accountant', 'writer', 'sound mixer', 'boom operator', 
    'location manager', 'assistant location manager', 'key grip', 'best boy grip', 
    'gaffer', 'best boy electric', 'transportation coordinator', 'picture car coordinator',
    'key set costumer', 'buyer', 'set decorator', 'lead man', 'production designer', 'art director',
    'music supervisor', 'composer', 'spfx coordinator', 'spfx buyer', 'editor', 'assistant editor',
  ];

  const departments = [
    'Production', 'Accounting', 'Talent', 'Writer', 'Camera', 'Sound', 'Construction', 'Art Department', 'Set Dec',
    'Grip', 'Electric', 'Stunts', 'Costumes', 'Hair and Makeup', 'Craft Services', 'Medic', 'Locations', 'Transportation', 'Music', 'Post Production', 'SPFX',
  ]

  const unionType = [
    'DGA', 
    'IATSE 484', 
    'IATSE 600', 
    'TEAMSTERS LOCAL 657', 
    'IATSE 700', 
    'IATSE 797', 
    'LMGI', 
    'PGA',
    'Association of Independent Commercial Producers', 
    'AFS Producer', 
    'SAG AFTRA', 
    'SAG Eligible', 
    'WGA West', 
    'WGA East', 
    'The American Society of Composers, Authors, and Publishers',
    'International Game Developers Association',
    'VES',
    'BMI',
    'None',
  ]
  
  useEffect(() => {
    // console.log("useEffect triggered for fetching crew data"); 
    const fetchCrewData = async () => {
      try {
        const response = await fetch(`https://second-team-productions.onrender.com/crew/${crewmember}`);
        // console.log("Fetching crew data for:", crewmember);
        if (!response.ok) {
          throw new Error('Failed to fetch crew data');
        }
        const data = await response.json();
        // console.log("Crew data received:", data); 
        setFormState(data); 
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };
    
    fetchCrewData();
  }, [crewmember]);

  const currentUser = AuthService.loggedIn(); 
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleSaveChanges = async () => {  

    if (!formState.position || formState.position.length === 0) {
      setPositionError("Position Required");
      return;
    }

    if (!formState.department || formState.department.length === 0) {
      setDepartmentError("Department Required");
      return;
    }

    if (!formState.unionType || formState.unionType.length === 0) {
      setUnionTypeError("Union/ Guild Affiliation Required.");
      return;
    }

    if (!formState.workRegion || formState.workRegion.length === 0) {
      setWorkRegionError("Work Region Required");
      return;
    }

    if (!formState.profileI || formState.profileI.length === 0) {
      setProfileIError("Profile Picture Required");
      return;
    }

    if (!formState.residence || formState.residence.length === 0) {
      setResidenceError("Proof of Residency Required");
      return;
    }

    if (!formState.location || formState.location.trim() === "") {
      setLocationError("Location Required");
      return; 
    }

    if (!formState.crewmember|| formState.crewmember.trim() === "") {
      setNameError("Name Required");
      return; 
    }

    if (!formState.email|| formState.email.trim() === "") {
      setEmailError("Email Required");
      return; 
    }

    if (!formState.phoneNumber|| formState.phoneNumber.trim() === "") {
      setPhoneNumberError("Phone Number Required");
      return; 
    }

    if (!formState.password|| formState.password.trim() === "") {
      setPhoneNumberError("Password Required");
      return; 
    }
  
    setPositionError(""); 
    setDepartmentError("");
    setUnionTypeError(""); 
    setWorkRegionError(""); 
    setProfileIError(""); 
    setLocationError("");
    setNameError("");
    setEmailError(""); 
    setPhoneNumberError(""); 
    setPasswordError("");  
    setResidenceError(""); 

    const formData = new FormData();
  
    formData.append('email', formState.email);
    formData.append('password', formState.password);
    formData.append('crewMember', formState.crewMember);
    formData.append('phoneNumber', formState.phoneNumber);
    formData.append('union', formState.union);
    formData.append('location', formState.location);
    formData.append('local', formState.local);
    formData.append('iMDB', formState.iMDB);
    
  
    if (Array.isArray(formState.workRegion)) {
      formState.workRegion.forEach(region => formData.append('workRegion', region));
    } else {
      formData.append('workRegion', formState.workRegion);
    }

    if (Array.isArray(formState.unionType)) {
      formState.unionType.forEach(type => formData.append('unionType', type));
    } else {
      formData.append('unionType', formState.unionType);
    }
  
    if (Array.isArray(formState.position)) {
      formState.position.forEach(pos => formData.append('position', pos));
    } else {
      formData.append('position', formState.position);
    }

    if (Array.isArray(formState.department)) {
      formState.department.forEach(pos => formData.append('department', pos));
    } else {
      formData.append('department', formState.department);
    }
  
    if (Array.isArray(formState.resumes)) {
      formState.resumes.forEach((resume, index) => {
        formData.append(`resumes[${index}].title`, resume.title);
        formData.append(`resumes[${index}].year`, resume.year);
        formData.append(`resumes[${index}].position`, resume.position);
      });
    }

    if (Array.isArray(formState.profileI) && formState.profileI.length > 0) {
      formState.profileI.forEach((file, index) => {
        formData.append(`profileI[${index}]`, file);
      });
    }

    if (formState.resumeP) {
      formData.append('resumeP', formState.resumeP);
    }

    if (formState.residence) {
      formData.append('residence', formState.residence);
    }

    if (formState.bio) {
      formData.append('bio', formState.bio);
    }
    
    // console.log('Form Data:', formState);
  
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value instanceof File ? value.name : value}`);
    // }
    
    try {
      const response = await fetch(`https://second-team-productions.onrender.com/crew/${crewmember}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        setFormState(formState);
        setIsEditing(false);
      } else {
        console.error("Failed to save changes");
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handlePositionChange = (e) => {
    const selectedValue = e.target.value;
  
    setFormState((prevState) => {
      const currentPosition = Array.isArray(prevState.position)
        ? prevState.position
        : prevState.position
        ? [prevState.position]
        : []; 

        if (currentPosition.length >= 3) {
          alert("You can only select up to 3 positions.");
          return prevState;
        }
      if (selectedValue && !currentPosition.includes(selectedValue)) {
        return {
          ...prevState,
          position: [...currentPosition, selectedValue],
        };
      }
      return prevState;
    });
  };

  const handleDepartmentChange = (e) => {
    const selectedValue = e.target.value;
  
    setFormState((prevState) => {
      const currentDepartment = Array.isArray(prevState.department)
        ? prevState.department
        : prevState.department
        ? [prevState.department]
        : []; 

        if (currentDepartment.length >= 3) {
          alert("You can only select up to 3 departments."); // or use setDepartmentError("You can only select up to 3 departments.");
          return prevState;
        }

      if (selectedValue && !currentDepartment.includes(selectedValue)) {
        return {
          ...prevState,
          department: [...currentDepartment, selectedValue],
        };
      }
      return prevState;
    });
  };
  
  const handleRegionChange = (e) => {
    const selectedValue = e.target.value;
  
    setFormState((prevState) => {
      const currentWorkRegion = Array.isArray(prevState.workRegion)
        ? prevState.workRegion
        : prevState.workRegion
        ? [prevState.workRegion]
        : [];
      if (selectedValue && !currentWorkRegion.includes(selectedValue)) {
        return {
          ...prevState,
          workRegion: [...currentWorkRegion, selectedValue],
        };
      }
      return prevState; 
    });
  };
  
  

  const handleUnionChange = (e) => {
    const selectedValue = e.target.value;
  
    setFormState((prevState) => {
      const currentUnionType = Array.isArray(prevState.unionType)
        ? prevState.unionType
        : prevState.unionType
        ? [prevState.unionType]
        : [];

      if (selectedValue && !currentUnionType.includes(selectedValue)) {
        return {
          ...prevState,
          unionType: [...currentUnionType, selectedValue],
        };
      }
  
      return prevState;
    });
  };

const handleChange = (e) => {
    const { name, value } = e.target;
  
if (name === 'unionType') {
      setFormState((prevState) => {
        const updatedUnionType = prevState.unionType.includes(value)
          ? prevState.unionType
          : [...prevState.unionType, value];
        return {
          ...prevState,
          unionType: updatedUnionType,
        };
      });
     }
     else if (name === 'local') {
      setSelectedLocal(value);
      setFormState({
        ...formState,
        [name]: value,
      });
    } else if (name === 'union') {
      setSelectedUnion(value); 
      setFormState({
        ...formState,
        [name]: value, 
      });
      
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleResumeChange = (index, field, value) => {
    const updatedResumes = formState.resumes.map((resume, i) =>
      i === index ? { ...resume, [field]: value } : resume
    );
    setFormState({ ...formState, resumes: updatedResumes });
  };
  
  const addResumeField = () => {
    setFormState({
      ...formState,
      resumes: [...formState.resumes, { title: '', year: '', position: '' }],
    });
  };

  const handleRemovePositionFromTable = (positionToRemove) => {
    const updatedPositions = Array.isArray(formState.position)
      ? formState.position.filter((pos) => pos !== positionToRemove)
      : []; 
  
    setFormState((prevData) => ({
      ...prevData,
      position: updatedPositions,
    }));
  };


  const handleRemoveDepartmentFromTable = (departmentToRemove) => {
    const updatedDepartments = Array.isArray(formState.department)
      ? formState.department.filter((pos) => pos !== departmentToRemove)
      : []; 
  
    setFormState((prevData) => ({
      ...prevData,
      department: updatedDepartments,
    }));
  };

  const handleRemoveRegionFromTable = (workRegionToRemove) => {
    const updatedRegions = Array.isArray(formState.workRegion)
      ? formState.workRegion.filter((pos) => pos !== workRegionToRemove)
      : [];
  
    setFormState((prevData) => ({
      ...prevData,
      workRegion: updatedRegions,
    }));
  };

  const handleRemoveUnionTypeFromTable = (unionTypeToRemove) => {
    const updatedUnion = Array.isArray(formState.unionType)
      ? formState.unionType.filter((pos) => pos !== unionTypeToRemove)
      : [];
  
    setFormState((prevData) => ({
      ...prevData,
      unionType: updatedUnion,
    }));
  };

  const handleRemoveResumesFromTable = (indexToRemove) => {
    const updatedResumes = formState.resumes.filter((_, index) => index !== indexToRemove);
    setFormState((prevData) => ({
      ...prevData,
      resumes: updatedResumes,
    }));
  };
  
  const handleRemoveImage = (index) => {
    const updatedProfileI = formState.profileI.filter((_, i) => i !== index);
    setProfilePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    setFormState((prevData) => ({
      ...prevData,
      profileI: updatedProfileI,
    }));
  };
  
  const handleAddImage = (event) => {
    const newFiles = Array.from(event.target.files).slice(0, 3);
  
    if (newFiles.length > 0) {
      setFormState((prevState) => {
        const updatedProfileI = [...prevState.profileI, ...newFiles].slice(0, 3);
        return {
          ...prevState,
          profileI: updatedProfileI,
        };
      });
  
      const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
      setProfilePreviews((prevPreviews = []) => {
        const existingPreviews = prevPreviews.slice(0, formState.profileI.length);
        return [...existingPreviews, ...newPreviews].slice(0, 3);
      });
    }
  };
  
  const handleDeleteResume = () => {
    setFormState((prevState) => ({
      ...prevState,
      resumeP: null,
    }));
    setIsResumeDeleted(true);
    // console.log('Resume deleted');
  };
  
const fileInputRef = useRef(null);

const handleUploadResume = (event) => {
  const file = event.target.files[0];
  if (file) {
    setFormState({ ...formState, resumeP: file });
    // console.log('Uploaded:', file);
    setIsResumeDeleted(false);
  }
};


  const fileInputRefResidence = useRef(null); 

  const handleResidenceUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormState({ ...formState, residence: file });
      // console.log('Uploaded Residence File:', file);
      setIsResidenceDeleted(false);  
    }
  };

  const handleDeleteResidence = () => {
    setFormState((prevState) => ({
      ...prevState,
      residence: null,
    }));
    setIsResidenceDeleted(true);
    console.log('Residence File deleted');
  };

  const canEdit = currentUser && currentUser.userId === crewmember;

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
 
  return (
    <div>
      <>
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <div className="row align-items-center" style={{ backgroundColor: 'black' }}></div>
                <div>
                <h1 className='title-font justify-content-center'
                style={{
                  marginTop: '2%',
                }}
                >
            {isEditing ? (
              <>
              <input className="about-card-edit"
              style={{
                background: 'none',
                border: 'none',
                // background: 'linear-gradient(324deg, rgb(42, 41, 41), black, rgb(54, 52, 52), #030303f4)',
                justifyContent: 'center',
                textAlign: 'center',
                color: 'silver',
                caretColor: 'grey'
              }}
                type="text"
                name="crewmember"
                value={formState.crewmember || ''}
                onChange={handleInputChange}
              />
              {nameError && (<p style={{ color: "red" }}>{nameError}</p>)}
              </>
            ) : (
              formState.crewmember
            )}
           </h1>
          </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
             <div>
               {formState && (
                isEditing ? (
                <div className="flex col-12">
                 <div>
                  <div style={{ display: 'flex', padding: 0, alignItems: 'center', justifyContent: 'center', }}>
                    {Array.isArray(formState.department) ? (
                      formState.department.map((department, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                            position: 'relative',
                            marginTop: '5%',
                          }}
                        >
                          <h1 style={{ marginRight: '10px', fontSize: '18px' }}>{department}</h1>
                          <Button
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              border: 'none',
                              borderRadius: '50%',
                              marginBottom: "5%",
                              width: '25px',
                              height: '25px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              padding: 0,
                            }}
                            onClick={() => handleRemoveDepartmentFromTable(department)}
                          >
                            x
                          </Button>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '15px',
                          position: 'relative',
                        }}
                      >
                        <h1 style={{ marginRight: '10px', fontSize: '18px' }}>{formState.department}</h1>
                        <Button
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            width: '25px',
                            height: '25px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: 0,
                          }}
                          onClick={() => handleRemoveDepartmentFromTable(formState.department)}
                        >
                          x
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                      <div>
                        <label htmlFor="department"></label>
                        <h4 style= {{
                        justifyContent: 'center', 
                      }}>
                          <select
                            name="department"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            id="department"
                            className="input-field"
                            style={{ 
                              padding: '5px', 
                              background: 'none',
                              color: 'silver',
                            }}
                          >
                            <option value="">add department</option>
                            {departments.map((department) => (
                              <option key={department} value={department}>
                                {department}
                              </option>
                            ))}
                          </select>
                        </h4>
                        {departmentError && <p style={{ color: 'red' }}>{departmentError}</p>}
                      </div>
                    </div>
                    ) : (
                      <div>
                        <div style={{ display: 'flex', listStyle: 'none', padding: 0, justifyContent: 'center',  }}>
                          {Array.isArray(formState.department) ? (
                            formState.department.map((department, index) => (
                              <h1
                                key={index}
                                style={{
                                  display: 'inline-block',
                                  marginRight: '15px',
                                }}
                              >
                                <span>{department}</span>
                              </h1>
                            ))
                          ) : (
                            <h1 style={{ display: 'inline-block', marginRight: '15px' }}>
                              <span>{formState.department}</span>
                            </h1>
                          )}
                        </div>
                      </div>
                    )
                  )}
                                
                <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {formState && (
                     isEditing ? (
                      <div className="flex col-12">
                        <div style={{ display: 'flex', padding: 0, alignItems: 'center', justifyContent: 'center',  }}>
                          {Array.isArray(formState.position) ? (
                            formState.position.map((position, index) => (
                              <h1
                                key={index}
                                style={{ 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  marginRight: '15px',
                                  position: 'relative',
                                  marginTop: '5%', }}
                               >
                                <h1 style={{ marginRight: '10px', fontSize: '18px' }}>{position}</h1>
                                <Button
                                  style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    marginBottom: "5%",
                                    width: '25px',
                                    height: '25px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: 0,
                                  }}
                                  onClick={() => handleRemovePositionFromTable(position)}
                                >
                                  x
                                </Button>
                              </h1>
                            ))
                          ) : (
                            <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '15px',
                              position: 'relative',
                            }}
                            >
                              <h1 style={{ marginRight: '10px', fontSize: '18px' }}>{formState.position}</h1>
                              <Button
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: 0,
                              }}
                                onClick={() => handleRemovePositionFromTable(formState.position)}
                              >
                                x
                              </Button>
                            </div>
                          )}
                        </div>
                        <div>
                          <label htmlFor="position">   
                          </label>
                          <h4 style= {{
                        justifyContent: 'center', 
                      }}>
                          <select
                            name="position"
                            value={selectedPosition}
                            onChange={handlePositionChange}
                            id="position"
                            className="input-field"
                            style={{ 
                            padding: '5px',
                            background: 'none',
                            color: 'silver', }}
                          >
                            <option value="">add position</option>
                            {positions.map((position) => (
                              <option key={position} value={position}>
                                {position}
                              </option>
                            ))}
                          </select>
                          </h4>
                          {positionError && (<p style={{ color: "red" }}>{positionError}</p>)}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
                          {Array.isArray(formState.position) ? (
                            formState.position.map((position, index) => (
                              <h1
                                key={index}
                                style={{
                                  display: 'inline-block',
                                  marginRight: '15px',
                                }}
                              >
                                <span>{position}</span>
                              </h1>
                            ))
                          ) : (
                            <h1 style={{ display: 'inline-block', marginRight: '15px' }}>
                              <span>{formState.position}</span>
                            </h1>
                          )}
                        </div>
                      </div>
                    )
                  )}
                    </div>
                  </div>
                </div>
                <div className="justify-content-center">
                  <div className="justify-content-center" style={{ position: 'relative', marginBottom: '5%' }}>
                    {formState && (
                      Array.isArray(formState.profileI) ? (
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                          {formState.profileI.map((profileI, index) => (
                            <div key={index} style={{ marginTop: '2%', flex: '0 0 30%', position: 'relative' }}>
                              <img
                                  src={
                                    typeof profileI === "string"
                                      ? profileI
                                      : profilePreviews[index] || URL.createObjectURL(profileI)
                                  }
                                alt={`crew image ${index + 1}`}
                                className="img-fluid"
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                  WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                                  transition: 'mask-image 0.3s ease',
                                  display: 'block',
                                }}
                              />
                              {isEditing && (
                               <Button
                               style={{
                                 position: 'absolute',
                                 top: '5px',
                                 right: '5px',
                                 backgroundColor: 'red',
                                 color: 'white',
                                 border: 'none',
                                 borderRadius: '50%',
                                 width: '25px',
                                 height: '25px',
                                 cursor: 'pointer',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                                 fontSize: '16px', 
                                 fontWeight: 'bold',
                                 padding: 0, 
                                 }}
                                 onClick={() => handleRemoveImage(index)}
                                >
                                  ✕
                                </Button>
                              )}
                            </div>
                          ))}
                          {isEditing && formState.profileI.length < 3 && (
                          <div style={{ flex: '0 0 30%', position: 'relative' }}>
                            <input
                              name="profileI"
                              type="file"
                              id="profileI"
                              accept="image/*"
                              multiple
                              onChange={handleAddImage}
                              style={{
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                                width: '100%',
                                height: '100%',
                              }}
                            />
                            <h1>
                            <button
                              style={{
                                padding: '10px 20px',
                                marginTop: '10px',
                                backgroundColor: 'green',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                width: '100%',
                                height: 'auto',
                              }}
                            >
                              + Add Image
                            </button>
                            </h1>
                            {profileIError && (<p style={{ color: "white" }}>{profileIError}</p>)}
                          </div>
                        )}
                        </div>
                        
                      ) : (
                        <div>
                          <img
                            src={formState.profileI}
                            alt="crew image"
                            className="img-fluid"
                            style={{
                              width: '35%',
                              height: 'auto',
                              maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                              WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                              transition: 'mask-image 0.3s ease',
                              display: 'block',
                              margin: '0 auto',
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div>
                {isEditing ? (
                  <Card
                    className="card-body"
                    style={{
                      marginTop: '2%',
                      marginBottom: '4%',
                      width: '90%',
                      display: 'block', 
                      margin: '0 auto', 
                    }}
                  >
                    <h3>bio</h3>
                    <div>
                      <Card>
                        <div className="row align-items-center">
                          <div>
                            <Card style={{ padding: '2%' }}>
                              <Card.Text>
                                <input
                                  className="about-card-edit"
                                  style={{
                                    background: 'none',
                                    border: 'none',
                                    height: '100%',
                                    width: '100%',
                                    justifyContent: 'center',
                                    textAlign: 'left',
                                    color: 'silver',
                                    caretColor: 'grey',
                                  }}
                                  type="text"
                                  name="bio"
                                  value={formState.bio || ''}
                                  onChange={handleInputChange}
                                />
                              </Card.Text>
                            </Card>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Card>
                ) : (
                  formState && formState.bio && (
                    <Card
                      className="card-body"
                      style={{
                        marginTop: '2%',
                        marginBottom: '3%',
                      
                        width: '90%',
                        display: 'block', 
                        margin: '0 auto', 
                      }}
                    >
                      <h3>bio</h3>
                      <div>
                        <Card>
                          <div className="row align-items-center">
                            <div>
                              <Card style={{ padding: '2%' }}>
                                <Card.Text>
                                  <div>
                                    {formState && formState.bio}
                                  </div>
                                </Card.Text>
                              </Card>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Card>
                  )
                )}
              </div>
                <div>
                <Card
                    className="card-body"
                    style={{
                      margin: '5%',
                      width: '90%',       
                      display: 'block', 
                      overflow: 'hidden',
                      boxSizing: 'border-box', 
                    }}>
                  <div>
                      <div className="d-flex flex-row justify-content-around"
                      style={{ 
                       padding: '2%',
                        marginTop: '2%',
                        marginBottom: '0%'
                        }}>          
                        <div className='col-3'
                        style={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          textAlign:'center',
                          marginTop: '5%' 
                          }}
                        >
                          <h3 style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            textAlign:'center' 
                            }}>Location</h3>   
                              <CardText>
                                <ListGroup style={{ border: 'none'}}>
                                  <ListGroupItem style={{ border: 'none', padding: '0' }}>
                                  {isEditing ? (
                                    <>
                                    <input className="about-card-edit"
                                    style={{
                                      background: 'none',
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                      color: 'silver',
                                      caretColor: 'grey',
                                      width: '100%'
                                    }}
                                      type="text"
                                      name="location"
                                      value={formState.location || ''}
                                      onChange={handleInputChange}
                                    />
                                    {locationError && (<p style={{ color: "red" }}>{locationError}</p>)}
                                    </>
                                  ) : (
                                    <div
                                    style={{ 
                                      border: 'none', 
                                      padding: '0',
                                      flexDirection: 'column', 
                                      alignItems: 'center', 
                                      textAlign:'center'            
                                       }}>
                                      {formState && formState.location}
                                    </div>   
                                  )}
                                  </ListGroupItem>
                                </ListGroup>
                              </CardText>
                        </div>
                     <div>
                      {formState && (
                      isEditing ? (
                      <div className="flex "
                      style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        textAlign:'center',
                         
                        }}> 
                        <div>
                        <h3 style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        textAlign:'center' 
                        }}>Work Region(s)</h3>
                          <CardText>
                            <ListGroup>
                            {Array.isArray(formState.workRegion) ? (
                            formState.workRegion.map((workRegion, index) => (
                              <ListGroupItem
                                key={index}
                                style={{
                                  display: 'flex',
                                  border: 'none', 
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                  
                                }}
                              >
                              {workRegion}
                                
                              <Button
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px', 
                                fontWeight: 'bold',
                                padding: 0, 
                                }}
                                  onClick={() => handleRemoveRegionFromTable(workRegion)}
                                >
                                 x 
                                </Button>
                              </ListGroupItem>
                            ))
                          ) : (
                            <ListGroupItem
                              style={{
                                display: 'flex',
                                border: 'none',
                                alignItems: 'center',
                                marginRight: '15px',
                              }}
                            >
                              <ListGroupItem
                                style={{
                                  display: 'flex',
                                  border: 'none',
                                  alignItems: 'center',
                                  marginRight: '15px',
                                }}
                              >{formState.workRegion}</ListGroupItem>
                              
                              <Button
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: 0,
                              }}
                                onClick={() => handleRemoveRegionFromTable(formState.workRegion)}
                              >
                                x
                              </Button>
                            </ListGroupItem>
                          )}
                            </ListGroup>
                          </CardText>  
                        </div>
                        
                        <div>
                        <label htmlFor="workRegion">
                        </label>
                          <h4>
                          <select                        
                            name="workRegion"
                            value={selectedRegion}
                            onChange={handleRegionChange}
                            id="workRegion"
                            className="input-field"
                            style={{ 
                            padding: '5px',
                            background: ' none',
                            color: 'silver', }}
                          >
                            <option value="">
                              <h1>
                                add region
                              </h1>
                            </option>
                            {workRegion.map((workRegion) => (
                              <option key={workRegion} value={workRegion}>
                                {workRegion}
                              </option>
                            ))}
                          </select>  
                          </h4>
                          {workRegionError && (<p style={{ color: "red" }}>{workRegionError}</p>)}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3
                          style={{ 
                            border: 'none', 
                            padding: '0',
                            textAlign:'center',
                            marginTop: '25%' 
                           }}
                            >Work Region(s)</h3>
                        <div style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
                          <CardText>
                          <ListGroup>
                          {Array.isArray(formState.workRegion) ? (
                            formState.workRegion.map((workRegion, index) => (
                              <ListGroupItem
                                key={index}
                                style={{ 
                                  border: 'none', 
                                  padding: '0',
                                  textAlign:'center',
                                  alignItems: 'center',
                                  justifyContent: 'center' }}
                              >
                                <span>{workRegion}</span>
                              </ListGroupItem>
                            ))
                          ) : (
                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                              <span>{formState.workRegion}</span>
                            </div>
                          )}  
                          </ListGroup>
                          </CardText>
                        </div>
                      </div>
                     )
                    )}
                  </div>
                  <Card style={{ padding: '2%' }}>
                    <h3
                      style={{
                        border: 'none',
                        padding: '0',
                        textAlign: 'center',
                        marginTop: '10%' 
                      }}
                    >
                      Will Work as a local
                    </h3>
                    <div className="row">
                      <div>
                        <CardText>
                          <ListGroup>
                            {isEditing ? (
                              <select
                                name="local"
                                value={formState.local}
                                onChange={handleChange}
                                id="local"
                                className="input-field"
                                style={{
                                  padding: '5px',
                                  background: 'none',
                                  color: 'silver',
                                }}
                              >
                                {!selectedLocal && (
                                  <option value="" disabled>
                                    Select Option
                                  </option>
                                )}
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            ) : (
                              <ListGroupItem
                                style={{
                                  border: 'none',
                                  padding: '0',
                                  textAlign: 'center',
                                }}
                              >
                                {formState && formState.local}
                              </ListGroupItem>
                            )}
                          </ListGroup>
                        </CardText>
                      </div>
                    </div>
                  </Card>
                  <Card style={{ padding: '2%' }}>
                  <div className="row">
                    <div className="flex col-3">
                    {formState && (
                      isEditing ? (
                      <div>
                        <h3
                          style={{ 
                          border: 'none', 
                          padding: '0',
                          textAlign:'center',
                          marginTop: '5%'  }}
                          >Union/ guild affiliations</h3>
                        <div 
                        style={{ 
                          border: 'none',
                          display: 'flex', padding: 0 }}>
                          <CardText>
                            <ListGroup>
                            {Array.isArray(formState.unionType) ? (
                            formState.unionType.map((unionType, index) => (
                              <ListGroupItem
                                key={index}
                                style={{
                                  display: 'flex',
                                  border: 'none', 
                                  alignItems: 'center',
                                  marginRight: '15px',
                                }}
                              >
                                <ListGroupItem
                                style={{
                                  display: 'flex',
                                  border: 'none', 
                                  alignItems: 'center',
                                  marginRight: '15px',
                                }}
                                >{unionType}</ListGroupItem>
                              <Button
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', 
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: 0,
                              }}
                              onClick={() => handleRemoveUnionTypeFromTable(unionType)}
                            >
                              x
                            </Button>
                              </ListGroupItem>
                            ))
                          ) : (
                            <ListGroupItem
                              style={{
                                display: 'flex',
                                border: 'none',
                                alignItems: 'center',
                                marginRight: '15px',
                                justifyContent: 'center'
                              }}
                            >
                              <ListGroupItem
                                style={{
                                  display: 'flex',
                                  border: 'none',
                                  alignItems: 'center',
                                  marginRight: '15px',
                                 
                                }}
                              >{formState.unionType}</ListGroupItem>
                              
                              <Button
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px', 
                                fontWeight: 'bold',
                                padding: 0, 
                                }}
                                onClick={() => handleRemoveUnionTypeFromTable(formState.unionType)}
                              >
                                x
                              </Button>
                            </ListGroupItem>
                          )}
                            </ListGroup>
                          </CardText>  
                        </div>
                        <div >
                          <h4>
                     <select
                      htmlFor="unionType"
                      name="unionType"
                      value={selectedUnionType}
                      onChange={handleUnionChange}
                      id="unionType"
                      className="input-field"
                      style={{
                        padding: '5px',
                        background: 'none',
                        width: '150px',
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        color: 'silver',
                      }}
                    >
                      <option value="" style={{ whiteSpace: 'normal' }}>
                        add union
                      </option>
                      {unionType.map((unionType) => (
                        <option key={unionType} value={unionType} style={{ whiteSpace: 'normal' }}>
                          {unionType}
                        </option>
                      ))}
                    </select>

                          </h4>
                          {unionTypeError && (<p style={{ color: "red" }}>{unionTypeError}</p>)}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3
                          style={{ 
                            border: 'none', 
                            padding: '0',
                            textAlign:'center',
                            marginTop: '20%'  }}
                            >Union/ guild affiliations</h3>
                        <div style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
                          <CardText>
                          <ListGroup>
                          {Array.isArray(formState.unionType) ? (
                            formState.unionType.map((unionType, index) => (
                              <ListGroupItem
                                key={index}
                                style={{ 
                                  border: 'none', 
                                  padding: '0',
                                  textAlign:'center',
                                 }}
                              >
                                <span>{unionType}</span>
                              </ListGroupItem>
                            ))
                          ) : (
                            <ListGroupItem 
                            style={{ 
                              display: 'inline-block', 
                              border: 'none',
                              marginRight: '15px',
                               }}>
                              <span>{formState.unionType}</span>
                            </ListGroupItem>
                          )}  
                          </ListGroup>
                          </CardText>
                        </div>
                      </div>
                     )
                    )}
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Card>
                </div>
              {(formState && Array.isArray(formState.resumes) && (formState.resumes.length > 0 || isEditing)) && (
              <Card
                className=" card-body"
                style={{
                  margin: '5%',
                  width: '90%',       
                  display: 'block', 
                  overflow: 'hidden',
                  boxSizing: 'border-box', 
                }}
                >
                <h3 className="justify-content-center">credits</h3>
                <Card>
                  <div>
                    <div className="justify-content-center">
                      {formState &&
                        Array.isArray(formState.resumes) &&
                        formState.resumes.map((resume, index) => (
                          <div key={index} className="d-flex flex-row w-100 my-3">
                            <Card className="p-2 m-2 flex-fill">
                              {isEditing ? (
                              <>
                                <div className="d-flex flex-row gap-3">
                                  <Card className="flex-fill">
                                    <h4
                                    style={{
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                    >Position</h4>
                                    <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                                      <ListGroupItem
                                        className="flex-fill"
                                        style={{ 
                                          border: 'none', 
                                          padding: '0',
                                          
                                        }}
                                      >
                              <input
                                className="about-card-edit"
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                                type="text"
                                name={`position-${index}`}
                                value={formState.resumes[index]?.position || ''}
                                onChange={(e) =>
                                  handleResumeChange(index, 'position', e.target.value)
                                }
                              />
                            </ListGroupItem>
                          </ListGroup>
                        </Card>

                        <Card className="flex-fill">
                          <h4
                          style= {{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          >Title</h4>
                          <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                            <ListGroupItem
                              className="flex-fill"
                              style={{ border: 'none', padding: '0', }}
                            >
                              <input
                                className="about-card-edit"
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                                type="text"
                                name={`title-${index}`}
                                value={formState.resumes[index]?.title || ''}
                                onChange={(e) =>
                                  handleResumeChange(index, 'title', e.target.value)
                                }
                              />
                            </ListGroupItem>
                          </ListGroup>
                        </Card>

                        <Card className="flex-fill">
                          <h4 style={{
                            alignItems: 'center',
                            justifyContent: 'center',}}>Year</h4>
                          <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                            <ListGroupItem
                              className="flex-fill"
                              style={{ border: 'none', padding: '0', alignItems: 'center',
                                justifyContent: 'center', }}
                            >
                              <input
                                className="about-card-edit"
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                                type="text"
                                name={`year-${index}`}
                                value={formState.resumes[index]?.year || ''}
                                onChange={(e) =>
                                  handleResumeChange(index, 'year', e.target.value)
                                }
                              />
                            </ListGroupItem>
                          </ListGroup>
                        </Card>
                      </div>
                      <div>
                        <Button
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px', 
                                fontWeight: 'bold',
                                padding: 0, 
                                }}
                            onClick={() => handleRemoveResumesFromTable(index)}
                          >
                            x
                          </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-row gap-3">
                        <Card className="flex-fill">
                          <h4
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          >Position</h4>
                          <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                            <ListGroupItem
                              className="flex-fill"
                              style={{ border: 'none', padding: '0' }}
                            >
                              <div
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                              >
                                {resume.position || 'N/A'}
                              </div>
                            </ListGroupItem>
                          </ListGroup>
                        </Card>

                        <Card className="flex-fill">
                          <h4
                          style={{
                            alignItems: 'center',
                           justifyContent: 'center',
                          }}
                          >Title</h4>
                          <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                            <ListGroupItem
                              className="flex-fill"
                              style={{ border: 'none', padding: '0' }}
                            >
                              <div
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                              >
                                {resume.title || 'N/A'}
                              </div>
                            </ListGroupItem>
                          </ListGroup>
                        </Card>

                        <Card className="flex-fill">
                          <h4
                          style={{
                            alignItems: 'center',
                           justifyContent: 'center',
                          }}
                          >Year</h4>
                          <ListGroup className="d-flex flex-row" style={{ border: 'none' }}>
                            <ListGroupItem
                              className="flex-fill"
                              style={{ border: 'none', padding: '0' }}
                            >
                              <div
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  color: 'silver',
                                  caretColor: 'grey',
                                }}
                              >
                                {resume.year || 'N/A'}
                              </div>
                            </ListGroupItem>
                          </ListGroup>
                        </Card>
                      </div>
                    </>
                  )}
                </Card>
              </div>
                    ))}
                </div>

                {isEditing ? (
                  <Button
                    className="process-card-text hover-brighten"
                    variant="link"
                    onClick={addResumeField}
                    style={{
                      position: 'relative',
                      marginLeft: '4vmin',
                      marginTop: '4.5vmin',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgb(0, 128, 0)',
                      border: 'none',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    <h4 style={{ margin: '1%' }}>Add</h4>
                  </Button>
                ) : (
                  <div></div>
                )}
              </div>
            </Card>
            
              </Card>
              )}

              {(formState && (formState.resumeP) && (formState.resumeP.length > 0 || isEditing)) || isEditing ? (
                <Card
                  className="card-body"
                  style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    marginRight: '25%',
                    marginLeft: '30%',
                    width: '40%',       
                    display: 'block',  
                    overflow: 'hidden',
                    boxSizing: 'border-box', 
                  }}
                >
                  <div className="justify-content-center" style={{ position: 'relative', }}>
                    {formState && formState.resumeP && !isResumeDeleted && !isEditing && (
                      <Button
                        onClick={() => {
                          const link = document.createElement('a');
                          link.href = formState.resumeP;
                          link.download = formState.resumeP.split('/').pop();
                          link.click();
                        }}
                        className="process-card-text hover-brighten"
                        style={{
                          display: 'block',
                          margin: '5px auto',
                          fontSize: '2.5vmin',
                          backgroundColor: 'rgb(67, 66, 66)',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }}
                      >
                        <h3 style={{ margin: '1%' }}>Download Resume</h3>
                      </Button>
                    )}

                    {formState && formState.resumeP && !isResumeDeleted && isEditing && (
                      <Button
                        onClick={handleDeleteResume}
                        style={{
                          display: 'block',
                          margin: '5px auto',
                          fontSize: '2.5vmin',
                          backgroundColor: 'rgb(200, 0, 0)',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          textAlign: 'center',
                          
                        }}
                      >
                        <h3 style={{ margin: '1%' }}>Delete Resume</h3>
                      </Button>
                    )}

                    {(!formState || !formState.resumeP || isResumeDeleted) && isEditing && (
                      <div style={{ marginTop: '10px' }}>
                        <Button
                          onClick={() => fileInputRef.current.click()}
                          style={{
                            display: 'block',
                            height: '40%',
                            width: 'auto',
                            margin: '5px auto',
                            fontSize: '2.5vmin',
                            backgroundColor: 'rgb(0, 128, 0)',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                        >
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept=".pdf,.doc,.docx"
                            style={{ display: 'none' }}
                            onChange={handleUploadResume}
                          />
                          <h3 style={{ margin: '1%' }}>Upload Resume</h3>
                        </Button>
                      </div>
                    )}
                  </div>
                </Card>
              ) : null}           
                <Card 
                  className=" card-body "
                  style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    marginRight: '20%',
                    marginLeft: '35%',
                    width: '30%',
                    height: 'auto',       
                    display: 'block', 
                    overflow: 'hidden',
                    boxSizing: 'border-box', 
                  }}>
                <div className="justify-content-center">
                  <div className='row align-items-center'
                  style={{
                    marginBottom: '5%',
                  }}
                  >
                 <div className="col-6">
                 {isEditing ? (
                    <>
                      <h4 style={{ color: 'silver', margin: '0 0 0.5rem 0' }}>Email</h4>
                      <input
                        className="about-card-edit"
                        style={{
                          background: 'none',
                          border: 'none',
                          height: '50%',
                          width: '100%',
                          justifyContent: 'center',
                          textAlign: 'left',
                          color: 'silver',
                          caretColor: 'grey',
                        }}
                        type="text"
                        name="email"
                        value={formState.email || ''}
                        onChange={handleInputChange}
                      />
                      {emailError && (<p style={{ color: "red" }}>{emailError}</p>)}
                    </>
                  ) : (
                    <>
                    <div className='col-6'
                    style={{
                      marginTop: '15%',
                     
                    }}
                    >
                    <a href={`mailto:${ formState && formState.email}`}>
                      <Button
                        className="process-card-text hover-brighten"
                        style={{
                          fontSize: '2.5vmin',
                          backgroundColor: 'rgb(67, 66, 66)',
                          border: 'none',
                          cursor: 'pointer',
                         
                        }}
                      >
                        <h4 style={{ margin: '1%' }}>EMAIL</h4>
                      </Button>
                    </a>
                    </div>
                    </>
                )}
                </div> 
                <div className='col-6'>
                    {isEditing ? (
                    <>
                    <div>
                    <h4 style={{ color: 'silver', margin: '0 0 0.5rem 0' }}>iMDB Link</h4>
                      <input
                        className="about-card-edit"
                        style={{
                          background: 'none',
                          border: 'none',
                          height: '50%',
                          width: '100%',
                          justifyContent: 'center',
                          textAlign: 'left',
                          color: 'silver',
                          caretColor: 'grey',
                        }}
                        type="text"
                        name="iMDB"
                        value={formState.iMDB || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    </>
                  ) : (
                    <>
                    <div style={{
                        marginLeft: '15%',
                        marginTop: '14%',
                        border: 'silver'
                        }}>
                    <a href={formState && formState.iMDB}>
                      <BiLogoImdb
                        style={{
                          color: 'gold',
                          marginLeft: '3.5vh',
                          fontSize: '6.5vmin',
                        }}
                      />
                    </a>
                    </div>
                    </>
                  )}
                </div> 
                  </div>
                  <div className='row'
                    style={{
                      marginBottom: '5%',
                    }}
                  >
                    <div className='col-6'>
                    {isEditing ? (
                    <>
                      <h4 style={{ color: 'silver', margin: '0 0 0.5rem 0' }}>Password</h4>
                      <input
                        className="about-card-edit"
                        style={{
                          background: 'none',
                          border: 'none',
                          height: '50%',
                          width: '100%',
                          justifyContent: 'center',
                          textAlign: 'left',
                          color: 'silver',
                          caretColor: 'grey',
                        }}
                        type="text"
                        name="password"
                        value={formState.password || ''}
                        onChange={handleInputChange}
                      />
                      {passwordError && (<p style={{ color: "red" }}>{passwordError}</p>)}
                    </>
                  ) : null} 
                    </div>
                  </div>
                  <div className='justify-content-center align-items-center'
                  style={{
                    marginLeft: "41%",
                  }}
                  >
                  {isEditing ? (
                   <div className="flex col-2">
                    <div style={{ marginTop: '10px' }}>
                     {formState && formState.residence && !isResidenceDeleted && (
                      <Button
                        onClick={handleDeleteResidence}
                         style={{
                          display: 'block',
                          height: '40%',
                          width: 'auto',
                          margin: '5px auto',
                          fontSize: '2.5vmin',
                          backgroundColor: 'rgb(200, 0, 0)',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          textAlign: 'center',
                          }}
                           >
                          <h3 style={{ margin: '1%' }}>Replace Residency Doc</h3>
                       </Button>
                        )}                               
                        {(!formState || !formState.residence || isResidenceDeleted) && (
                         <div style={{ marginTop: '10px' }}>
                          <Button
                           onClick={() => fileInputRefResidence.current.click()} 
                            style={{
                            display: 'block',
                            height: '40%',
                            width: 'auto',
                            margin: '5px auto',
                            fontSize: '2.5vmin',
                            backgroundColor: 'rgb(0, 128, 0)',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            }}
                             >
                            <input
                             ref={fileInputRefResidence}
                             type="file"
                             accept=".pdf,.doc,.docx"
                             style={{ display: 'none' }}
                             onChange={handleResidenceUpload}
                              />
                            <h3 style={{ margin: '1%' }}>Upload Residency Doc</h3>
                          </Button>
                    </div>
                    )}
                  </div>
                {residenceError && <p style={{ color: 'white' }}>{residenceError}</p>}
                </div>          
                  ) : null} 

                  </div>
                </div>
                </Card>
              </div>
            </div>
          </>
       </>
      {AuthService.loggedIn() && (
        <div>
          {isEditing && (
            <Button 
              className="process-card-text hover-brighten"
              style={{
                fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
              onClick={handleSaveChanges}
              >
              <h4 style={{ margin: '1%' }}>Save</h4>
            </Button>
          )}
          {canEdit && (
         <Button
         className="process-card-text hover-brighten"
         style={{
           fontSize: '2.5vmin',
           backgroundColor: 'rgb(67, 66, 66)',
           border: 'none',
           cursor: 'pointer',
           marginTop: isEditing ? '5%' : '0%',
           marginLeft: '42%',
           marginBottom: '5%',
         }}
         onClick={() => {
           if (isEditing) {
            
             setFormState(originalData); 
           } else {
             setOriginalData(formState);
           }
           setIsEditing((prev) => !prev); 
         }}
         disabled={!canEdit}
       >
         <h4 style={{ margin: '1%' }}>
           {isEditing ? 'Cancel' : 'Edit Profile'}
         </h4>
       </Button>
          )}
        </div>
      )}
  </div>
 );

}

export default CrewProfile;