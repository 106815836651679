import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ADD_TO_CART } from './utils/actions';
import { idbPromise } from './utils/helpers';

class ObjectTrackCards {
    constructor(objectData, cardWidth, cardHeight) {
        this.name = objectData.entity.description;
        this.price = objectData.entity.price 
            ? parseFloat(objectData.entity.price.toString().replace('$', ''))
            : 0; 
        this.details = objectData.entity.details;
        this.startTime = nullableTimeOffsetToSeconds(objectData.segment.start_time_offset);
        this.endTime = nullableTimeOffsetToSeconds(objectData.segment.end_time_offset);
        this.confidence = objectData.confidence;
        this.cardHeight = cardHeight;
        this.cardWidth = cardWidth;

        this.frames = objectData.frames.map(frame => ({
            card: {
                width: this.cardWidth,
                height: this.cardHeight
            },
            timeOffset: nullableTimeOffsetToSeconds(frame.time_offset)
        }));
    }

    hasFramesForTime(seconds) {
        return this.startTime <= seconds && this.endTime >= seconds;
    }

    mostRecentRealCard(seconds) {
        for (let i = 0; i < this.frames.length; i++) {
            if (this.frames[i].timeOffset > seconds) {
                return i > 0 ? this.frames[i - 1].card : null;
            }
        }
        return null;
    }

    currentCard(seconds) {
        return this.mostRecentRealCard(seconds);
    }
}

export { ObjectTrackCards };

export function createTrackCards({ cardTracks, video, setCardContent, dispatch }) {
    if (!Array.isArray(cardTracks)) {
        console.error('Invalid tracks data: Expected an array but received:', typeof cardTracks);
        return;
    }

    const currentTime = video.currentTime;
    const cardMap = new Map();

    cardTracks.forEach(track => {
        if (track instanceof ObjectTrackCards && track.hasFramesForTime(currentTime)) {
            const displayCard = track.currentCard(currentTime);
            if (displayCard) {
                const cardKey = track.name;

                if (!cardMap.has(cardKey)) {
                    cardMap.set(cardKey, {
                        card: displayCard,
                        name: track.name,
                        price: track.price,
                        details: track.details,
                        confidence: track.confidence
                    });
                }
            }
        } else {
            console.warn('Object is not an instance of ObjectTrackCards:', track);
        }
    });

    const contentArray = Array.from(cardMap.values()).map(({ card, name, price, details, confidence }) =>
        createTrackCard({ dispatch, card, name, price, details, confidence })
    );

    setCardContent(contentArray);
}

function createTrackCard({ dispatch = null, card, name = null, price = null, details = null }) {
    // Return a function component instead of a functional call
    return <TrackCard dispatch={dispatch} card={card} name={name} price={price} details={details} />;
}

function TrackCard({ dispatch, card, name, price, details }) {
    const [isAdding, setIsAdding] = useState(false); // State to track if an item is being added

    if (!card) {
        console.warn('No card to draw');
        return null;
    }

    const addToCart = async () => {
        if (isAdding) return; // Prevent multiple clicks
        setIsAdding(true); // Set flag to prevent further clicks

        const item = {
            _id: name || 'TrackCard-' + Date.now(), // Ensure there's a unique _id
            name: name || 'Track Card',
            price: price || 0,
            details: details || 'No details',
            purchaseQuantity: 1,
        };

        console.log('Adding to cart:', item);

        if (dispatch) {
            try {
                dispatch({
                    type: ADD_TO_CART,
                    item,
                });

                await idbPromise("cart", "put", item);
                console.log('Item added to IndexedDB:', item);
            } catch (error) {
                console.error('Error with idbPromise:', error);
            } finally {
                setIsAdding(false); // Reset the flag after the action completes
            }
        } else {
            console.error('Dispatch function is not available');
            setIsAdding(false); // Reset the flag if dispatch is not available
        }
    };

    return (
        <div>
            <h3>{name || 'Track Card'}</h3>
            <h5>${price !== null && price !== undefined ? price : 'Price not available'}</h5>
            <p>{details !== null && details !== undefined ? details : 'Details not available'}</p>
            <Button
                className="process-card-text hover-brighten"
                style={{
                    position: 'relative',
                    marginLeft: '6.5vmin',
                    marginTop: '4.5vmin',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgb(67, 66, 66)',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onClick={addToCart}
                disabled={isAdding} // Disable the button if adding is in progress
            >
                <h5 style={{ margin: 0 }}>{isAdding ? 'Adding...' : 'Add to Cart'}</h5>
            </Button>
        </div>
    );
}


export function nullableTimeOffsetToSeconds(timeOffset) {
    if (!timeOffset) return 0;

    let seconds = timeOffset.seconds || 0;
    seconds += timeOffset.nanos / 1e9 || 0;
    return seconds;
}
