import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import '../components/style/global.css';
import Home from './pages/Home';
import Header from './Header';
import Footer from './Footer';
import About from './pages/About';
import MovieProfile from './pages/MovieProfile';
import Movies from './pages/Movies';
import HitchcockCollection from './pages/Hitchcock';
import Submit from './pages/Submit';
import { Provider } from 'react-redux';
import store from './utils/store.js';
import Login from './pages/Login.js';
import SignUp from './pages/signUp.js';
import PrivacyTOS from './pages/PrivacyTOS.js';

//Test
import HomeTest from './pages/HomeTest';
import MovieProfileTest from './pages/MovieProfileTest';
import CrewProfile from './pages/crewProfile';
import SiteDirectory from './pages/siteDirectory';
import Cart from './pages/Cart.js';
import { AuthProvider } from '../components/utils/AuthContext.js'; 



function WebsiteContainer() {
  const [currentPage, setCurrentPage] = useState('Home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div>
      <AuthProvider> 
      <Provider store={store}>
      <Header currentPage={currentPage} handlePageChange={handlePageChange} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/:moviename" element={<MovieProfile />} />
        <Route path="/Movies/:moviename" element={<Movies />} />
        <Route path="/HitchcockCollection" element={<HitchcockCollection />} />
        <Route path="/Submit" element={<Submit />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp/>} />
        <Route path="/Privacy" element={<PrivacyTOS/>} />

        
        <Route path="/HomeTest" element={<HomeTest />} />
        <Route path="/test/:moviename" element={<MovieProfileTest />} />
        <Route path="/crewProfile/:crewmember" element={<CrewProfile/>} /> 
        <Route path="/TFDirect" element={<SiteDirectory/>} /> 
        <Route path="/Cart" element={<Cart/>} /> 
      </Routes>
     
      <Footer currentPage={currentPage} handlePageChange={handlePageChange} />
      </Provider>
      </AuthProvider>
    </div>
    
  );
}

export default WebsiteContainer;
