import React from "react";
import Links from "./Links";
import { Nav } from 'react-bootstrap';
import FTabs from "./FTabs";
import { Button } from "react-bootstrap";
import tx from './assets/txflag.svg';

function Footer({ currentPage, handlePageChange }) {
  return (
    <footer className="footer text-center" style={{ width: "100%", }}>
      <div className="align-items-center">
        <FTabs currentPage={currentPage} handlePageChange={handlePageChange} />
        <div  style={{ borderTop: "1px solid silver",
        width: "100%", 
        position: 'relative', 
       
        backgroundSize: '25% auto', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',}}>
        <div 
        style={{ width: "100%", 
        marginBottom:'1.5vmin',
        marginTop:'1.5vmin',
        }}>
            <Links currentPage={currentPage} handlePageChange={handlePageChange} />
        </div>

        <div className="d-flex justify-content-center">
          <Nav className="justify-content-center">
            <Nav.Link href="/Privacy" className="custom-nav-link">
              Privacy & Terms of Service
            </Nav.Link>
          </Nav>
        </div>

        <div>
          <h4 className="justify-content-center">
          <a href="mailto:contact@secondteamproductions.com">
         <Button 
         style={{
          marginTop: '7.5%',
          marginBottom:'7.5%',
          fontSize: '2.5vmin',
          backgroundColor: 'rgb(67, 66, 66)',
          border: 'none',
          cursor: 'pointer',
          
        }}
         >Contact Us</Button>
        </a>
          </h4>
        </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
