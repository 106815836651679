import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SecondTeamLogo from './assets/2ndTeamF.jpg';
import Auth from './utils/auth';

const NavLinks = () => {
  const navigate = useNavigate();

  if (Auth.loggedIn()) {
    const userProfile = Auth.getProfile();
    // console.log('Auth.getProfile:', userProfile);
    
    const handleProfileClick = () => {
      navigate(`/crewProfile/${userProfile.userId}`);
    };
    
    return (
      <Nav>
       <Nav.Link onClick={handleProfileClick} className="custom-nav-link" style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
          Profile
        </Nav.Link>
        <Nav.Link onClick={() => Auth.logout()} className="custom-nav-link" style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
          Logout
        </Nav.Link>
      </Nav>
    );
  } else {
    return (
      <Nav>
        <Nav.Link href="/Login" className="custom-nav-link">
          Login
        </Nav.Link>
      </Nav>
    );
  }
};

function NavTabs({ currentPage, handlePageChange }) {
  return (
    <Navbar collapseOnSelect expand="xxl" variant="dark">
      <Navbar.Brand href="/" onClick={() => handlePageChange('Home')} className={currentPage === 'Home' ? 'nav-link active' : 'nav-link'} style={{ flex: '0 0 auto' }}>
        <img src={SecondTeamLogo} alt="Second Team Logo" style={{ maxWidth: '40vmin', maxHeight: '40vmin', marginRight: '12px' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/" onClick={() => handlePageChange('Home')} className={currentPage === 'Home' ? 'nav-link active' : 'nav-link'} style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
            Home
          </Nav.Link>
          <NavLinks /> 
          <Nav.Link href="/TFDirect" onClick={() => handlePageChange('Directory')} className={currentPage === 'Directory' ? 'nav-link active' : 'nav-link'} style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
            TFDirect
          </Nav.Link>
          <Nav.Link href="/Cart" onClick={() => handlePageChange('Cart')} className={currentPage === 'Submit' ? 'nav-link active' : 'nav-link'} style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
            Cart
          </Nav.Link>
          <Nav.Link href="/Submit" onClick={() => handlePageChange('Submit')} className={currentPage === 'Submit' ? 'nav-link active' : 'nav-link'} style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
            Submit
          </Nav.Link>
          <Nav.Link href="/About" onClick={() => handlePageChange('About')} className={currentPage === 'About' ? 'nav-link active' : 'nav-link'} style={{ fontSize: "2vmin", flex: '0 0 auto' }}>
            About
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavTabs;
