import React from 'react';
import {Card,} from 'react-bootstrap';

export default function PrivacyTOS() {
  return (
    <div style={{ marginBottom: '7%',}}>

      <div className='container'> 
     
      <div className='row align-items-center about-page-content'>
        <div className='d-flex justify-content-center about-us-header'>
        <h1 className='section-title'> PRIVACY POLICY & TERMS OF SERVICE</h1>
        </div>
        <br></br>
        <div>
          <Card> 
        <div>
          <div className='col-12'> 
          <div> 
          <Card className="card-body" 
          style={{
            padding:'5%',
            marginBottom:'10%'}}>
              <Card.Text>
                <div className='d-flex justify-content-center'>
                <h2>
                 PRIVACY POLICY
                </h2>
                </div>

                <Card.Text>
                Effective Date: 03/31/2025
                </Card.Text>

                <Card.Text>
                1.1 Introduction
                <li>
                2nd Team Productions LLC ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website www.secondteamproductions.com (the "Website").
                </li> 
                </Card.Text>

                <Card.Text>
                1.2 Information We Collect  
                  <li>
                  - Personal Information: Name, email address, phone number, and other identifying details when voluntarily provided.
                  </li>
                  <li>
                  - Non-Personal Information: IP address, browser type, and usage data collected through cookies and analytics tools.
                  </li>
                </Card.Text>

                <Card.Text>
                1.3 How We Use Your Information 
                <li>
                - To provide and improve our services.
                </li>
                <li>
                - To respond to inquiries and provide customer support.
                </li> 
                <li>
                - To analyze Website usage and enhance user experience.
                </li> 
                <li>
                - To comply with legal obligations.
                </li>  
                </Card.Text>
                <Card.Text>
                1.4 Sharing Your Information  
                <li>
                We do not sell, trade, or rent your personal information. We may share your information with third-party service providers for business operations or legal compliance.
                </li>
                </Card.Text>

                <Card.Text>
                  1.5 Security  
                  <li>
                  We take appropriate measures to secure your data but cannot guarantee absolute security.
                  </li>
                </Card.Text>

                  <Card.Text>
                  1.6 Your Rights 
                  <li>
                  You may request access, correction, or deletion of your data by contacting us at contact@secondteamproductions.com.
                  </li> 
                  </Card.Text>

                  <Card.Text>
                  1.7 Changes to This Policy 
                  <li>
                  We reserve the right to update this Privacy Policy. Continued use of the Website constitutes acceptance of any changes.
                  </li> 
                  </Card.Text>
              </Card.Text>
              </Card>

              <Card className="card-body" style={{padding:'5%',}}>
              <Card.Text>
                <div className='d-flex justify-content-center'>
                <h2>
                 Terms of Service
               </h2>
                </div>

                 <Card.Text>
                  1.1 Acceptance of Terms
                  <li>
                  By accessing or using our Website, you agree to these Terms of Service ("Terms"). If you do not agree, do not use our Website.
                  </li>
                  </Card.Text>

                  <Card.Text>
                  1.2 Use of the Website
                  <li>
                  You agree to use the Website only for lawful purposes and in accordance with these Terms.
                  </li>  
                  </Card.Text>

                  <Card.Text>
                  1.3 Intellectual Property
                  <li>
                  All content on this Website, including text, images, and trademarks, is owned by or licensed to 2nd Team Productions LLC. Unauthorized use is prohibited.
                  </li>  
                  </Card.Text>

                  <Card.Text>
                  1.4 User Conduct
                  <li>
                  You agree not to:
                  <li>
                  - Engage in any unlawful or fraudulent activity.
                  </li>
                  <li>
                  - Disrupt the operation of the Website.
                  </li>
                  <li>
                  - Use automated means to extract data.
                  </li>
                  </li>
                  </Card.Text>

                  <Card.Text>
                  1.5 Limitation of Liability
                  <li>
                  We are not liable for any direct, indirect, or incidental damages resulting from your use of the Website.
                  </li>  
                  </Card.Text>

                  <Card.Text>
                  1.6 Changes to Terms
                  <li>
                  We reserve the right to modify these Terms. Continued use of the Website after changes constitutes acceptance of the new Terms.
                  </li> 
                  </Card.Text>

                  <Card.Text>
                  1.7 Governing Law
                  <li>
                  These Terms are governed by the laws of the State of Texas.
                  </li> 

                  </Card.Text>

                  <Card.Text>
                  1.8 Contact Us
                  <li>
                  For any questions, please email contact@secondteamproductions.com.
                  </li>  
                  </Card.Text>
              </Card.Text>
            </Card>
            </div>
            </div>
            </div>
            </Card>
            </div>
        </div>
        
      </div>
    </div>
  );
}
