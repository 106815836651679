import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardText, ListGroup, ListGroupItem } from 'react-bootstrap';

const SearchDirectory = () => {
  const [searchTerm, setSearchTerm] = useState(''); 
  const [searchTermLocation, setSearchTermLocation] = useState(''); 
  const [selectedRegion, setSelectedRegion] = useState(''); 
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedUnion, setSelectedUnion] = useState('');
  const [selectedLocal, setSelectedLocal] = useState('');   
  const [data, setData] = useState([]); 
  const [isLoaded, setIsLoaded] = useState(false); 
  const [error, setError] = useState(null); 
  const regions = ['Central', 'East', 'Dallas Fort Worth', 'Gulf Coast', 'Panhandle', 'West']; 
  const position =[
    'actor', 'producer', 'executive producer', 'co-executive producer',
    'showrunner', 'unit production manager', 'production coordinator', 'director', 
    'assistant director', ' 1st assistant director', '2nd assistant director', 
    'camera operator',  'key hair', 'key makeup', 'production accountant',
    '1st assistant accountant', 'writer', 'sound mixer', 'boom operator', 
    'location manager', 'assistant location manager', 'key grip', 'best boy grip', 
    'gaffer', 'best boy electric', 'transportation coordinator', 'picture car coordinator',
    'key set costumer', 'buyer', 'set decorator', 'lead man', 'production designer', 'art director',
    'music supervisor', 'composer', 'spfx coordinator', 'spfx buyer', 'editor', 'assistant editor',
    ];
    const department = [
      'Production', 'Accounting', 'Talent', 'Writer', 'Camera', 'Sound', 'Construction', 'Art Department', 'Set Dec',
      'Grip', 'Electric', 'Stunts', 'Costumes', 'Hair and Makeup', 'Craft Services', 'Medic', 'Locations', 'Transportation', 'Music', 'Post Production', 'SPFX',
    ]

    const fetchData = async () => {
      // console.log('Fetching data...');
      setIsLoaded(false);
      try {
        const response = await fetch(
          `https://second-team-productions.onrender.com/crew?workRegion=${selectedRegion}&term=${searchTerm}&term=${searchTermLocation}&position=${selectedPosition}&department=${selectedDepartment}&unionStatus=${selectedUnion}&localStatus=${selectedLocal}`
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        // console.log('Data fetched:', result);
    
        // Filter data here after fetching
        const filteredData = result.filter((item) => {
          const workRegion = Array.isArray(item.workRegion)
            ? item.workRegion.join(', ').toLowerCase()
            : typeof item.workRegion === 'string'
            ? item.workRegion.toLowerCase()
            : '';
    
          const position = typeof item.position === 'string'
            ? item.position.toLowerCase()
            : '';

            const department = Array.isArray(item.department)
            ? item.department.join(', ').toLowerCase()
            : typeof item.department === 'string'
            ? item.department.toLowerCase()
            : '';
    
          const union = item.union && typeof item.union === 'string'
            ? item.union.toLowerCase()
            : '';
    
          const local = item.local && typeof item.local === 'string'
            ? item.local.toLowerCase()
            : '';
    
          const crewmember = item.crewmember && typeof item.crewmember === 'string'
            ? item.crewmember.toLowerCase()
            : '';
    
          const location = item.location && typeof item.location === 'string'
            ? item.location.toLowerCase()
            : '';
    
          return (
            (!searchTerm || crewmember.includes(searchTerm.toLowerCase())) &&
            (!searchTermLocation || location.includes(searchTermLocation.toLowerCase())) &&
            (!selectedPosition || position.includes(selectedPosition.toLowerCase())) &&
            (!selectedRegion || workRegion.includes(selectedRegion.toLowerCase())) &&
            (!selectedDepartment || department.includes(selectedDepartment.toLowerCase())) &&
            (!selectedUnion || union === selectedUnion.toLowerCase()) &&
            (!selectedLocal || local === selectedLocal.toLowerCase())
          );
        });
    
        setData(filteredData); // Store filtered data in state
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setIsLoaded(true);
      }
    };
    
    // Inside your component's render or return function
    if (error) {
      return <div>Error: {error}</div>;
    }
    

    return (
      <div className="container"
        style={{
          marginTop: '4%',
          marginBottom: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1
          className="title-font justify-content-center"
          style={{
            marginTop: '4%',
            marginBottom: '1%',
          }}
        >
          TFDirect
        </h1>
        
        <Card
          className=" card-body"
          style={{
            margin: '5%',
            height: '10%',
            width: 'auto',
            padding: '20px',
            position: 'relative',
            color: 'white',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <div>
            <div className="row align-items-center">
            <div className="col-4">
              <h4>Name</h4>
              <input
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              />
            </div>
            <div className="col-4">
              <h4>Department</h4>
              <select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <option value="">Select Department</option>
                {department.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <h4>Position</h4>
              <select
                value={selectedPosition}
                onChange={(e) => setSelectedPosition(e.target.value)}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <option value="">Select Position</option>
                {position.map((pos) => (
                  <option key={pos} value={pos}>
                    {pos}
                  </option>
                ))}
              </select>
            </div>
          </div>
        
            <div className="row align-items-center">
            <div className="col-4">
              <h4>Location</h4>
              <input
                type="text"
                placeholder="ex) Austin, TX"
                value={searchTermLocation}
                onChange={(e) => setSearchTermLocation(e.target.value)}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              />
            </div>
            <div className="col-4">
              <h4>Work Region</h4>
              <select
                value={selectedRegion}
                onChange={(e) => setSelectedRegion(e.target.value)}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <option value="">Select Region</option>
                {regions.map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <h4>Willing to Work as a Local</h4>
              <select
                name="local"
                value={selectedLocal}
                onChange={(e) => setSelectedLocal(e.target.value)}
                id="local"
                className="input-field"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <option value="">Select Option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            </div>
            
            <div className="col-4">
              <h4>Union Status</h4>
              <select
                name="union"
                value={selectedUnion}
                onChange={(e) => setSelectedUnion(e.target.value)}
                id="union"
                className="input-field"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <option value="">Select Option</option>
                <option value="union">Union</option>
                <option value="nonunion">Non-Union</option>
              </select>
            </div>
            <div className="col-4">
              <Button
                className="process-card-text hover-brighten"
                style={{
                  fontSize: '2.5vmin',
                  backgroundColor: 'rgb(67, 66, 66)',
                  border: 'none',
                  cursor: 'pointer',
                  marginTop: '10%',
                }}
                onClick={fetchData}
              >
                <h4 style={{ margin: '1%' }}>Search</h4>
              </Button>
            </div>
          </div>
        </Card>
    
        {isLoaded && (
          <div className="d-flex flex-wrap justify-content-center"
          style={{ gap: '1.5%' }}
          >
            {data.length > 0 ? (
              data.map((item) => (
                <div key={item.id} className="col-4 col-sm-3 col-md-3 col-lg-2" >
                  <Card
                    className="card-body custom-border-directory justify-content-center"
                    style={{
                      margin: '1.5%',
                      padding: '1.5%',
                    }}
                  >
                    <Link
                      to={`/crewProfile/${item && item.crewmember}`}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <Card.Img
                        src={Array.isArray(item.profileI) ? item.profileI[0] : item.profileI}
                        className="col-4"
                        alt={`${item.crewmember}`}
                        style={{
                          maskImage:
                            'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                          WebkitMaskImage:
                            'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                          transition: 'mask-image 0.3s ease',
                        }}
                      />
                      <div
                        className="container"
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                      >
                        <CardText>
                          <ListGroup>
                            <ListGroupItem
                              style={{ 
                                border: 'none', 
                                padding: '0',
                                textAlign:'center' }}
                             >
                            <h3 className="col-12 justify-content-center">{item.crewmember}</h3>
                            </ListGroupItem>
                            <ListGroupItem
                                style={{
                                  border: 'none',
                                  padding: '0',
                                  textAlign: 'center',
                                }}
                              >
                                {Array.isArray(item.position)
                                  ? item.position.map((pos, index) => (
                                      <h4 key={index} className="col-12 justify-content-center">
                                        {pos}
                                      </h4>
                                    ))
                                  : item.position && (
                                      <h4 className="col-12 justify-content-center">{item.position}</h4>
                                    )}
                              </ListGroupItem>
                            <ListGroupItem
                              style={{ 
                                border: 'none', 
                                padding: '0',
                                textAlign:'center' }}
                             >
                            <h4 className="col-12 justify-content-center">{item.location}</h4>
                            </ListGroupItem>
                          </ListGroup>
                        </CardText>
                        <div className="col-12">
                          <ListGroup>
                            <ListGroupItem
                             style={{ 
                              border: 'none', 
                              padding: '0',
                              textAlign:'center' }}
                            >
                            <Button
                            className="process-card-text hover-brighten"
                            style={{
                              fontSize: '2.5vmin',
                              backgroundColor: 'rgb(67, 66, 66)',
                              border: 'none',
                              cursor: 'pointer',
                              marginBottom: '5%'
                            }}
                          >
                            <Link
                              to={`/crewProfile/${item && item.crewmember}`}
                              style={{
                                textDecoration: 'none',
                              }}
                            >
                              <h4 style={{ margin: '1%' }}>Profile</h4>
                            </Link>
                          </Button>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      </div>
                    </Link>
                  </Card>
                </div>
              ))
            ) : (
              <>
               <div
               style={{
                margin: '5%',
               }}
               >
               <h1 className="title-font justify-content-center">No results found</h1>
                </div>
             
              </>
           
            )}
          </div>
        )}
      </div>
    );
    
};

export default SearchDirectory;
