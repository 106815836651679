import React, { useEffect } from "react";
import {Button, Card} from 'react-bootstrap';
// import { loadStripe } from "@stripe/stripe-js";
import { idbPromise } from "../utils/helpers";
import CartItem from "../cartItem";
// import Auth from "../utils/auth";
// import react-redux
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_CART, ADD_MULTIPLE_TO_CART } from "../utils/actions";

// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

const Cart = () => {
  // create dispatch from useDispatch()
  const dispatch = useDispatch();
  // create state from useSelector()
  const state = useSelector((s) => s);

  // UseEffect to load cart items from IndexedDB
  useEffect(() => {
    async function getCart() {
      const cartItems = await idbPromise("cart", "get");
      console.log('iDB cart:', cartItems);
      dispatch({ type: ADD_MULTIPLE_TO_CART, products: [...cartItems] });
    }
  
    if (!state.cart.length) {
      console.log('Fetching cart from IDB');
      getCart();
    }
  }, [state.cart.length, dispatch]);
  

  function calculateTotal() {
    let sum = 0;
    state.cart.forEach((item) => {
      sum += item.price * item.purchaseQuantity;
    });
    return sum.toFixed(2);
  }

  function submitCheckout() {
  }

  console.log('Cart Items:', state.cart);




  return (
    <div className="container flex-row space-between"
    style={{
      height: '100vh', 
      width: '100vw',
    }}>
        <h1
        className="title-font justify-content-center"
        style={{
          marginTop: '4%',
          marginBottom: '1%',
        }}
      >
        shopping cart
      </h1>
      {state.cart.length ? (
        <div 
        style={{
          color:'white',
          margin:'2%'
        }}
        >
          {state.cart.map((item) => (
            <CartItem key={item.listing} item={item}/>
          ))}
          <div className="flex-row space-between">
            <div  style={{
          
          marginTop:'2%'
        }}>
              <h3>
                Total: ${calculateTotal()} 
              </h3> </div>
            
              <Button className="process-card-text hover-brighten" 
               style={{
                fontSize: '2.5vmin',
                 backgroundColor: 'rgb(67, 66, 66)',
                 border: 'none',
                 cursor: 'pointer',
                 marginLeft: '30%',
               }}
               onClick={submitCheckout}
              >
                <h4 style={{ margin: "1%" }}>
                  checkout</h4>
              </Button>
          </div>
        </div>
      ) : (
        <>
        <div className="container flex-row space-between"
        style={{
          marginTop: '30%'
        }}>
        <Card>
         <h1 className=" title-font justify-content-center">Cart Empty</h1>
        </Card> 
        </div>
        </>
    
      )}
    </div>
  );
};

export default Cart;
