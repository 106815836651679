import React, { useState } from "react";
import { Card, Button } from 'react-bootstrap';
import secondTeam from '../assets/2ndTeam.jpg';
// import { validateEmail } from "../utlls/helpers";

function Submit() {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    personTitle:"",
    synopsis: "",
    phoneNumber:"",
    city:"",
    shootLocation: "",
    runTime:"",
    title:"",
    media1:"",
    media2:"",
    media3:"",
    cctexas:"",
    cctotal:"",

  });

  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
 

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    personTitle:"",
    synopsis: "",
    phoneNumber:"",
    city:"",
    shootLocation: "",
    runTime:"",
    title:"",
    cctexas:"",
    cctotal:"",
  });

  function handleChange(e) {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: e.target.value ? "" : "This field is required" });
  }
  

  async function handleSubmit(e) {
    e.preventDefault();

    const hasErrors = Object.values(formErrors).some((error) => error !== "");

    if (hasErrors) {
      setErrorMessage("*Please fill out all fields*");
      
      return;
    }

    console.log('Form state before fetch:', formState);
    try {
      const response = await fetch("https://second-team-productions.onrender.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: 'application/json, text/plain, */*',
        },
        body: JSON.stringify(formState),
      });
      
      if (response.ok) {
        console.log("Form submitted successfully");
        // Reset the form
        setFormState({
          firstName: "",
          lastName: "",
          email: "",
          personTitle: "",
          synopsis: "",
          phoneNumber: "",
          city: "",
          shootLocation: "",
          runTime:"",
          title:"",
          media1:"",
          media2:"",
          media3:"",
          cctexas:"",
          cctotal:"",
        });

        // Set the submitted state to true
        setSubmitted(true);

      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="container" style={{ maxWidth: "100vmin", }}>
    {submitted ? (
  <div style={{ 
    marginBottom: '40%',
    marginTop: '40%' }}>
    <h1 className=" justify-content-center"
    style={{ marginBottom: '3%', }}>
      Thank you for your submission, we'll get back to you soon!
    </h1>
    <div>
      <div className='d-flex justify-content-center'
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          boxShadow: '0px 0px 10px white',
        }}
      >
        <img src={secondTeam} alt='secondteamlogo' className="img-fluid" />
      </div>
    </div>
  </div>
) : (

    <>
       <div> 
          <Card style={{
            marginTop: '5%'}}>
              <h1 className="justify-content-center section-title"> Submit Your Film</h1>
              <br></br>
              <div style={{
                marginBottom: '4%'}}>
              <h3 className="justify-content-center process-card"
              style={{
                marginBottom:'0%',
                }}>Our Process</h3>
              <div className="process-card"
              >
              <Card.Text style={{
                padding: '1%',
                marginTop:'1%',}}>
                If you would like to have your film hosted on 2nd Team Productions, please
                fill out the form below and we'll contact you to schedule a time
                to discuss your film as well as your goals. 
              </Card.Text>
              <Card.Text style={{
                padding: '1%'}}>
                If it's a suitable fit, we'll ask you to provide your film and sizzle reel, 
                as well as information about the film's cast and crew (contact information, bios, links to 
                professional sites etc.).
              </Card.Text>
              <Card.Text style={{
                padding: '1%'}}>
                From there, we'll generate your film's webpage which will include your reel,
                movie, and information about your cast and crew.
              </Card.Text>
              </div>
              </div>
            </Card>
            </div>
      <br />
      <div className="container"
      style={{
        marginBottom:'2.5%',
        }}>
      <Card>
        <form onSubmit={handleSubmit}
        className="row justify-content-center"
        style={{
          marginBottom:'2.5%',
          marginTop:'2.5%'
          }}
         >
           <h3 className="justify-content-left process-card"
          >Filmmaker Information</h3>
            <div className="container process-card"
             style={{
              marginBottom:'7.5%'}}
          >
            <div className="row align-items-center "
          style={{
            marginBottom:'3%',
            marginTop:'2.5%',
            }}
           >
            <div className="col-6"
           >
              <label>
                <h4 className="process-card"
                >
                 First name</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.firstName}
                onChange={handleChange}
                name="firstName"
                placeholder="Firstname"
                className="process-card-text"
              />
            </div>
            <div className="col-6"
            
           >
              <label>
                <h4 className="process-card"
                >
                  Last name</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.lastName}
                onChange={handleChange}
                name="lastName"
                placeholder="Lastname"
                className="process-card-text"
              />
            </div>
        
          </div>
          <div className="row align-items-center"
          style={{
            marginBottom:'3%'}}
          >
          <div className="col-6"
            >
              <label>
              <h4 className="process-card"
                >
                  Phone Number</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.phoneNumber}
                onChange={handleChange}
                name="phoneNumber"
                placeholder="(000) 000-0000"
                className="process-card-text"
              />
            </div>
            <div className="col-6"
           >
              <label>
              <h4 className="process-card"
                >
                  Email</h4>
              </label>
              <br />
              <input
                type="email"
                value={formState.email}
                onChange={handleChange}
                name="email"
                placeholder="johndoe@gmail.com"
                className="process-card-text"
              />
            </div>
          </div>
          <div className="row align-items-center"
          style={{
            marginBottom:'3%',
            }}>
          <div className="col-6"
            >
              <label>
              <h4 className="process-card"
                >
                  City</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.city}
                onChange={handleChange}
                name="city"
                placeholder="City"
                className="process-card-text"
              />
            </div>
            </div>
          
          <div className="row align-items-center"
           style={{
            marginBottom:'3%'}}>
            <div className="col-8">
              <label htmlFor="status">
              <h4 className="process-card"
              >
                  Are you a Producer, Director, Actor?</h4>
              </label>
              <select
                className="col-3 text-center process-card-text"
                value={formState.personTitle}
                onChange={handleChange}
                name="personTitle"
                style={{
                  marginLeft:'10%'}}
              >
                <option value="" disabled selected>Select</option>
                <option value="Producer">Producer</option>
                <option value="Director">Director</option>
                <option value="Actor">Actor</option>
              </select>
            </div>
          </div>
            </div>
            <h3 className="justify-content-left process-card"
          >
            Film information</h3>
          <div className=" process-card">
            <div>
            <div className="col-6"
            style={{
              marginBottom:'3%',
              marginTop:'2.5%'
              }}
            >
              <label>
              <h4 className="process-card"
                >
                  Title</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.title}
                onChange={handleChange}
                name="title"
                placeholder="title"
                className="process-card-text"
              />
            </div>
              <label htmlFor="message">
                  <h4 className="process-card" 
              >
                synopsis
              </h4>
              </label>
              <br />
              <textarea
                className="col-12 col-sm-10 col-md-10 process-card-text"
                value={formState.synopsis}
                onChange={handleChange}
                name="synopsis"
                placeholder="Your film's synopsis"
                rows="6"
                style={{
                 padding: '2%',
                 marginBottom:'3%'}}
              />
            </div>
          <div className="row align-items-center">
            <div className="col-6"
            style={{
            marginBottom:'3%'}}
            >
              <label>
              <h4 className="process-card"
                >
                  Run time</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.runTime}
                onChange={handleChange}
                name="runTime"
                placeholder="1hr 30mins"
                className="process-card-text"
              />
            </div>
            <div className="col-6"
            style={{
            marginBottom:'3%'}}
            >
              <label>
              <h4 className="process-card"
                >
                  Shoot Location</h4>
              </label>
              <br />
              <input
                type="text"
                value={formState.shootLocation}
                onChange={handleChange}
                name="shootLocation"
                placeholder="Austin"
                className="process-card-text"
              />
            </div>
            <div>
              <label>
              <h4 className="process-card">
                Cast and Crew
              </h4>
              </label>
            </div>
            <div className="row align-items-center">
            <div className="col-6"
            style={{
            marginBottom:'3%'}}
            >
              <label>
              <h5 className="process-card"
                >
                  Total # cast and crew</h5>
              </label>
              <br />
              <input
                type="text"
                value={formState.cctotal}
                onChange={handleChange}
                name="cctotal"
                placeholder="00"
                className="process-card-text"
              />
            </div>
            <div className="col-6"
            style={{
            marginBottom:'3%'}}
            >
              <label>
              <h5 className="process-card"
                >
                  total # that are texas residents</h5>
              </label>
              <br />
              <input
                type="text"
                value={formState.cctexas}
                onChange={handleChange}
                name="cctexas"
                placeholder="00"
                className="process-card-text"
              />
            </div>
            </div>
            
            
           
            <div className="col-12"
            style={{
            marginBottom:'2%'}}
            >
              <label>
              <h4 className="process-card"
               style={{
                marginBottom: "1%",
              }}
                >
                  Links to media (optional/ if applicable)</h4>
              </label>
              <br />
              <div className="row align-items-center"
            >
              <input
                type="text"
                value={formState.media1}
                onChange={handleChange}
                name="media1"
                placeholder="link"
                className="col-3 process-card-text"
                style={{
                  margin: "2%",
                }}
              />
                <input
                type="text"
                value={formState.media2}
                onChange={handleChange}
                name="media2"
                placeholder="link"
                className="col-3 process-card-text"
                style={{
                  margin: "2%",
                }}
              />
                <input
                type="text"
                value={formState.media3}
                onChange={handleChange}
                name="media3"
                placeholder="link"
                className="col-3 process-card-text"
                style={{
                  margin: "2%",
                }}
              />
              </div>
            </div>
            </div>
          </div>
          <br />
          {errorMessage && (
            <div>
              <h2 className="error-text justify-content-center"
              style={{
                marginTop: "2%",
              }}
              
              >{errorMessage}</h2>
            </div>
          )}
          <div>
            <div>
              <Button 
              className="process-card-text hover-brighten" 
              type="submit"
              style={{
                    marginTop: "2.5%",
                    backgroundColor: 'rgb(90, 90, 90)',
                    border: 'none',
                  }}
              > 
              <h4 className=" text-center"
               style={{
                margin: "1%",
                
              }}
              >
                  Submit</h4>
                </Button>
            </div>
          </div>
        </form>
        </Card>
      </div>
      
    </>
    )}
    </div>
  );
}

export default Submit;
