import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { Button, Card, CardText} from 'react-bootstrap';
import Auth from '../utils/auth';

function Login(props) {
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://second-team-productions.onrender.com/crew/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formState),
      });

      if (!response.ok) {
        throw new Error('Failed to log in');
      }

      const { token } = await response.json();
      Auth.login(token); 
    } catch (e) {
      console.error(e);
      setError("The provided credentials are incorrect"); 
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <div className="container"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', 
      width: '100vw',
     }}
     >
      <form onSubmit={handleFormSubmit}>
      <Card className= 'card-body '
       style={{ 
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginTop: '50%',
        width: '100%',
        marginBottom: '50%',
        position: 'relative', 
        color: 'white',
        borderRadius: '10px' }}>
          <h1 className="title-font justify-content-center"
         style={{
          
          marginBottom: '1%',
        }}
        >Login</h1>

    <div className='container col-12 justify-content-center row' 
      style={{ 
        display: 'block', 
        flexWrap: 'wrap', 
        gap:'5%',
        margin:'2%'}}>
        <div className="flex col-12">
          <label htmlFor="email">
            <h4>
            Email address 
            </h4>
            </label>
          <input
            placeholder=""
            name="email"
            type="email"
            id="email"
            className="input-field"
            onChange={handleChange}
            style={{
              width: '100%',
              maxWidth: '100%',
              marginBottom: '10px',
              boxSizing: 'border-box',
            }}
          />
        </div>
         
        <div className="flex col-12">
          <label htmlFor="pwd">
          <h4>
            password
          </h4>
          </label>
          <input
            placeholder=""
            name="password"
            type="password"
            id="pwd"
            className="input-field"
            onChange={handleChange}
            style={{
              width: '100%',
              maxWidth: '300px',
              marginBottom: '10px',
              boxSizing: 'border-box',
            }}
          />
        </div>
        {error && (
          <div>
            <p className="error-text">{error}</p>
          </div>
        )}
        </div>
    <div className="flex-row flex-end">
        <Button type="submit" className="process-card-text hover-brighten"
          style={{
            position: 'relative',
            marginLeft: '6.5vmin',
            marginTop: '4.5vmin',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgb(67, 66, 66)',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
             <h4 style={{ margin: '1%' }}>
        login
          </h4>
        </Button>
        </div>
        <CardText>
          Don't have an account? Sign up 
          <Link to="/Signup" className='go-to-signup-link'
           style={{
            color:'white',
            fontWeight: 'bold', 
            textDecoration:'none'
          }}> Here</Link>
        </CardText>
        </Card>
      </form>
    </div>
  );
}

export default Login;
