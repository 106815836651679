import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'; 
import { Button, Card, CardText} from 'react-bootstrap';
import Auth from '../utils/auth';

function Signup(props) {
  const [formState, setFormState] = useState({ 
    email: '', 
    password: '', 
    crewMember: '',
    phoneNumber: '',
    union: '',
    location: '',
    local: '',
    bio:'',
    iMDB: '',
    workRegion: [], 
    unionType: [],
    resumes: [{ title: '', year: '', position: '' }],
    resumeP: null,
    residence: null,
    position: [],
    department: [],
    profileI: [],  
   });
  const regions = ['Central', 'East', 'Dallas Fort Worth', 'Gulf Coast', 'Panhandle', 'West'];
  const positions = [
    'actor', 'producer', 'executive producer', 'co-executive producer',
    'showrunner', 'unit production manager', 'production coordinator', 'director', 
    'assistant director', '1st assistant director', '2nd assistant director', 'script supervisor', 'stunt coordinator',
    'camera operator',  'key hair', 'key makeup', 'production accountant',
    '1st assistant accountant', 'writer', 'sound mixer', 'boom operator', 
    'location manager', 'assistant location manager', 'construction coordinator', 'foreman- general','key grip', 'best boy grip', 
    'gaffer', 'best boy electric', 'transportation coordinator', 'driver', 'picture car coordinator', 'craft services',
    'key set costumer', 'buyer', 'set decorator', 'lead man', 'production designer', 'art director',
    'music supervisor', 'composer', 'spfx coordinator', 'spfx buyer', 'editor', 'assistant editor', 'production assistant',
  ];

  const departments = [
    'Production', 'Accounting', 'Talent', 'Writer', 'Camera', 'Sound', 'Construction', 'Art Department', 'Set Dec',
    'Grip', 'Electric', 'Stunts', 'Costumes', 'Hair and Makeup', 'Craft Services', 'Medic', 'Locations', 'Transportation', 'Music', 'Post Production', 'SPFX',
  ]

  const uType = [
    'DGA', 
    'IATSE 484', 
    'IATSE 600', 
    'TEAMSTERS LOCAL 657', 
    'IATSE 700', 
    'IATSE 797', 
    'LMGI', 
    'PGA',
    'Association of Independent Commercial Producers', 
    'AFS Producer', 
    'SAG AFTRA', 
    'SAG Eligible', 
    'WGA West', 
    'WGA East', 
    'The American Society of Composers, Authors, and Publishers',
    'International Game Developers Association',
    'VES',
    'BMI',
    'None',
  ]
  
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedUnionType, setSelectedUnionType] = useState('');
  const [selectedLocal, setSelectedLocal] = useState('');
  const [selectedUnion, setSelectedUnion] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [profilePreviews, setProfilePreviews] = useState([]);
  const [resumePreview, setResumePreview] = useState(null);
  const [residencePreview, setResidencePreview] = useState(null);
  const [isResumeDeleted, setIsResumeDeleted] = useState(false);
  const [isResidenceDeleted, setIsResidenceDeleted] = useState(false);

  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [departmentError, setDepartmentError] = useState(null);
  const [unionError, setUnionError] = useState(null);
  const [unionTypeError, setUnionTypeError] = useState(null);
  const [workRegionError, setWorkRegionError] = useState(null);
  const [localError, setLocalError] = useState(null);
  const [profileIError, setProfileIError] = useState(null);
  const [residenceError, setResidenceError] = useState(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formState.crewMember.trim()) {
      setNameError('Name required');
    }
  
    if (!formState.email.trim()) {
      setEmailError('Email required');
    }

    if (!formState.password.trim()) {
      setPasswordError('Password required');
    }

    if (!formState.phoneNumber.trim()) {
      setPhoneNumberError('Phone Number required');
    }
    if (!formState.location.trim()) {
      setLocationError('Location required');
    }

    if (!formState.position || formState.position.length === 0) {
      setPositionError('Position required');
    } else {
      setPositionError(null);
    }

    if (!formState.department || formState.department.length === 0) {
      setDepartmentError('Department required');
    } else {
      setDepartmentError(null);
    }

    if (!formState.union || formState.union.length === 0) {
      setUnionError('Union Status required');
    } else {
      setUnionError(null);
    }

    if (!formState.unionType || formState.unionType.length === 0) {
      setUnionTypeError('Union/ Affiliation field required');
    } else {
      setUnionTypeError(null);
    }

    if (!formState.workRegion || formState.workRegion.length === 0) {
      setWorkRegionError('Work Region required');
    } else {
      setWorkRegionError(null);
    }

    if (!formState.local || formState.local.length === 0) {
      setLocalError('Please Select Yes or No');
    } else {
      setLocalError(null);
    }

    if (!formState.profileI || formState.profileI.length === 0) {
      setProfileIError('Profile Picture Required');
    } else {
      setProfileIError(null);
    }

    if (!formState.residence || formState.residence.length === 0) {
      setResidenceError('Please Upload Proof of Residency');
    } else {
      setResidenceError(null);
    }
    
    const formData = new FormData();
  
    formData.append('resumeP', formState.resumeP);
    formData.append('residence', formState.residence);
    formData.append('email', formState.email);
    formData.append('password', formState.password);
    formData.append('crewMember', formState.crewMember);
    formData.append('phoneNumber', formState.phoneNumber);
    formData.append('union', formState.union);
    formData.append('location', formState.location);
    formData.append('local', formState.local);
    formData.append('bio', formState.bio);
    formData.append('iMDB', formState.iMDB);
  
    if (Array.isArray(formState.workRegion)) {
      formState.workRegion.forEach(region => formData.append('workRegion', region));
    } else {
      formData.append('workRegion', formState.workRegion);
    }

    if (Array.isArray(formState.unionType)) {
      formState.unionType.forEach(type => formData.append('unionType', type));
    } else {
      formData.append('unionType', formState.unionType);
    }
  
    if (Array.isArray(formState.position)) {
      formState.position.forEach(pos => formData.append('position', pos));
    } else {
      formData.append('position', formState.position);
    }

    if (Array.isArray(formState.department)) {
      formState.department.forEach(pos => formData.append('department', pos));
    } else {
      formData.append('department', formState.department);
    }
  
    if (Array.isArray(formState.resumes)) {
      formState.resumes.forEach((resume, index) => {
        formData.append(`resumes[${index}].title`, resume.title);
        formData.append(`resumes[${index}].year`, resume.year);
        formData.append(`resumes[${index}].position`, resume.position);
      });
    }

    if (Array.isArray(formState.profileI)) {
      formState.profileI.forEach((file, index) => {
        formData.append(`profileI[${index}]`, file);
      });
    }
  
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value instanceof File ? value.name : value}`);
    // }
  
    try {
      const response = await fetch('https://second-team-productions.onrender.com/crew/signup', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to register user');
      }
  
      const { token } = await response.json();
      Auth.login(token);
  
      // console.log('Signup successful for:', formState.crewMember);
    } catch (e) {
      console.error(e);
      
    }
  };

  const handleRemoveImage = (index) => {
    const updatedProfileI = formState.profileI.filter((_, i) => i !== index);
    setFormState((prevData) => ({
      ...prevData,
      profileI: updatedProfileI,
    }));
    setProfilePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };
  
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files).slice(0, 3);
    setFormState((prevState) => ({
      ...prevState,
      profileI: [...prevState.profileI, ...files],
    }));
  };
  
  const fileInputRefResume = useRef(null);

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormState({ ...formState, resumeP: file });
      setResumePreview(URL.createObjectURL(file));
      setIsResumeDeleted(false); 
      // console.log('Uploaded file:', file);
    }
  };


  const handleDeleteResume = () => {
    setFormState((prevState) => ({
      ...prevState,
      resumeP: null,
    }));
    setIsResumeDeleted(true);
    // console.log('Resume deleted');
  };

  const fileInputRefResidence = useRef(null); 


  const handleResidenceUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormState({ ...formState, residence: file });
      setResidencePreview(URL.createObjectURL(file));
      setIsResidenceDeleted(false);  
      // console.log('Uploaded Residence File:', file);
    }
  };

  const handleDeleteResidence = () => {
    setFormState((prevState) => ({
      ...prevState,
      residence: null,
    }));
    setIsResidenceDeleted(true);
    // console.log('Residence File deleted');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'workRegion') {
      setFormState((prevState) => {
        const updatedRegions = prevState.workRegion.includes(value)
          ? prevState.workRegion
          : [...prevState.workRegion, value];
        return {
          ...prevState,
          workRegion: updatedRegions,
        };
      });
     }
     else if (name === 'unionType') {
      setFormState((prevState) => {
        const updatedUnionType = prevState.unionType.includes(value)
          ? prevState.unionType
          : [...prevState.unionType, value];
        return {
          ...prevState,
          unionType: updatedUnionType,
        };
      });
     }
     else if (name === 'local') {
      setSelectedLocal(value);
      setFormState({
        ...formState,
        [name]: value,
      });
    } else if (name === 'union') {
      setSelectedUnion(value); 
      setFormState({
        ...formState,
        [name]: value, 
      });
      
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };
  
  const handleResumeChange = (index, field, value) => {
    const updatedResumes = formState.resumes.map((resume, i) =>
      i === index ? { ...resume, [field]: value } : resume
    );
    setFormState({ ...formState, resumes: updatedResumes });
  };
  
  const addResumeField = () => {
    setFormState({
      ...formState,
      resumes: [...formState.resumes, { title: '', year: '', position: '' }],
    });
  };

  const handlePositionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue && !formState.position.includes(selectedValue)) {
      setFormState((prevState) => ({
        ...prevState,
        position: [...prevState.position, selectedValue],
      }));
    }
    setSelectedPosition('');
  };

  const handleDepartmentChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue && !formState.department.includes(selectedValue)) {
      setFormState((prevState) => ({
        ...prevState,
        department: [...prevState.department, selectedValue],
      }));
    }
    setSelectedDepartment('');
  };

  return (
    <div className="container">
      <form onSubmit={handleFormSubmit} enctype="multipart/form-data">
        <h1   className="title-font justify-content-center"
        style={{
          marginTop: '4%',
          marginBottom: '1%',
        }}>Signup</h1>
        <Card className='card-body'
        style={{ 
          margin: '5%',
          height: '10%',
          width: 'auto',
          padding: '50px',
          position: 'relative',
          color: 'white',
          overflow: 'hidden',
          boxSizing: 'border-box', 
          }}>
        <div>
      <div className='row align-items-center'>
        <div className="col-4">
          <label htmlFor="crewMember">
            <h4>
              name 
            </h4>
          </label>
          <input
            placeholder="Fullname"
            name="crewMember"
            type="text"
            id="crewMember"
            className="input-field"
            onChange={handleChange}
            style={{
              width: '100%',
              maxWidth: '100%',
              marginBottom: '10px',
              boxSizing: 'border-box',
            }}
          />
          {nameError && <p style={{ color: 'white' }}>{nameError}</p>}
        </div>
        <div className="col-4">
          <label htmlFor="email">
          <h4>
            email 
          </h4>
          </label>
          <input
            placeholder="youremail@email.com"
            name="email"
            type="email"
            id="email"
            className="input-field"
            onChange={handleChange}
            style={{
              width: '100%',
              maxWidth: '100%',
              marginBottom: '10px',
              boxSizing: 'border-box',
            }}
          />
          {emailError && <p style={{ color: 'white' }}>{emailError}</p>}
        </div>
        <div className="col-4">
              <label htmlFor="phoneNumber">
              <h4>
               phone number
              </h4>
              </label>
              <input
                placeholder="(000) 000-0000"
                name="phoneNumber"
                type="phoneNumber"
                id="phoneNumber"
                className="input-field"
                onChange={handleChange}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              />
              {phoneNumberError && <p style={{ color: 'white' }}>{phoneNumberError}</p>}
          </div>
        </div>

      <div className='row align-items-center'>
            <div className="flex col-4">
              <label htmlFor="location">
              <h4>
              location
            </h4>
              </label>
              <input
                placeholder="City, State"
                name="location"
                type="location"
                id="location"
                className="input-field"
                onChange={handleChange}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              />
              {locationError && <p style={{ color: 'white' }}>{locationError}</p>}
            </div>
            <div className="col-4">
            <label htmlFor="workRegion">
              <h4>Work Region(s)</h4>
            </label>
            <select
              name="workRegion"
              value={selectedRegion}
              onChange={handleChange}
              id="workRegion"
              className="input-field"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
            >
              <option value="">Select Region</option>
              {regions.map((workRegion) => (
                <option key={workRegion} value={workRegion}>
                  {workRegion}
                </option>
              ))}
            </select>
            <div>
              <h5>Selected Regions:</h5>
              <ul>
                {formState.workRegion.map((region, index) => (
                  <li key={index}>{region}</li>
                ))}
              </ul>
            </div>
            {workRegionError && <p style={{ color: 'white' }}>{workRegionError}</p>}
          </div>
          <div className="col-4">
            <label htmlFor="local">
              <h4>Are you willing to work as a local?</h4>
            </label>
            <select
              name="local"
              value={selectedLocal}
              onChange={handleChange}
              id="local"
              className="input-field"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {localError && <p style={{ color: 'white' }}>{localError}</p>}
          </div>       
      </div> 
        <div className='row justify-content-center'>  
            <div className="col-6">
            <label htmlFor="position">
              <h4>Department(s)</h4>
            </label>
            <select
              name="department"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              id="position"
              className="input-field"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
            >
              <option value="">Select Department</option>
              {departments.map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </select>
            <div >
              <h5>Selected Department(s):</h5>
              <ul>
                {formState.department.map((pos, index) => (
                  <li key={index}>{pos}</li>
                ))}
              </ul>
              {departmentError && <p style={{ color: 'white' }}>{departmentError}</p>}
            </div>
          </div>
          <div className="col-6">
            <label htmlFor="position">
              <h4>Position(s)</h4>
            </label>
            <select
              name="position"
              value={selectedPosition}
              onChange={handlePositionChange}
              id="position"
              className="input-field"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
            >
              <option value="">Select Position</option>
              {positions.map((position) => (
                <option key={position} value={position}>
                  {position}
                </option>
              ))}
            </select>
            <div>
              <h5>Selected Positions:</h5>
              <ul>
                {formState.position.map((pos, index) => (
                  <li key={index}>{pos}</li>
                ))}
              </ul>
              {positionError && <p style={{ color: 'white' }}>{positionError}</p>}
            </div>
          </div>

          </div>
          <div className='row justify-content-center'>  
            <div className="col-6">
              <label htmlFor="union">
              <h4>
              Union Status
            </h4></label>
              <select
                name="union"
                value={selectedUnion}
                onChange={handleChange}
                id="union"
                className="input-field"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
                >
                <option value="">Select Option</option>
                <option value="Union">Union</option>
                <option value="Non-Union">Non-Union</option>
              </select>
              {unionError && <p style={{ color: 'white' }}>{unionError}</p>}
            </div>
             
          <div className="col-6">
            <label htmlFor="unionType">
              <h4>Union/Guild Affiliation</h4>
            </label>
            <select
              name="unionType"
              value={selectedUnionType}
              onChange={handleChange}
              id="unionType"
              className="input-field"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
            >
              <option value="">Select Union/Guild</option>
              {uType.map((union) => (
                <option key={union} value={union}>
                  {union}
                </option>
              ))}
            </select>
            <div>
              <h5>Selected Affiliations:</h5>
              <ul>
                {formState.unionType.map((union, index) => (
                  <li key={index}>{union}</li>
                ))}
              </ul>
            </div>
            {unionTypeError && <p style={{ color: 'white' }}>{unionTypeError}</p>}
          </div>
          </div>
            
            <div className="flex justify-content-center ">
              <label>
              <h4>
              credits
              </h4>
              </label>
              <div>
              {formState.resumes.map((resume, index) => (
                <div key={index} className=" row align-items-center">
                  <div className='col-4'>
                  <h5>show</h5>
                  <input
                    placeholder={`Show `}
                    type="text"
                    value={resume.title}
                    className="input-field"
                    onChange={(e) => handleResumeChange(index, 'title', e.target.value)}
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      marginBottom: '10px',
                      boxSizing: 'border-box',
                    }}
                  />
                  </div>
                  <div className='col-4'>
                  <h5>year</h5>
                  <input
                    placeholder={`Year`}
                    type="text"
                    value={resume.year}
                    className="input-field"
                    onChange={(e) => handleResumeChange(index, 'year', e.target.value)}
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      marginBottom: '10px',
                      boxSizing: 'border-box',
                    }}
                  />
                  </div>
                  <div className='col-4'>
                  <h5>role</h5>
                  <input
                    placeholder={`Role`}
                    type="text"
                    value={resume.position}
                    className="input-field"
                    onChange={(e) => handleResumeChange(index, 'position', e.target.value)}
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      marginBottom: '10px',
                      boxSizing: 'border-box',
                    }}
                  />
                  </div>
                </div>
              ))}
              </div>
              
              <Button
                className="process-card-text hover-brighten"
                variant="link"
                onClick={addResumeField}
                style={{
                  position: 'relative',
                  marginLeft: '4vmin',
                  marginTop: '4.5vmin',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgb(67, 66, 66)',
                  border: 'none',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <h4 style={{ margin: '1%' }}>Add</h4>
              </Button>
            </div>
          <div className="row justify-content-center" style={{ marginBottom: '2%' }}>
            <div className="flex col-6">
             <div style={{ marginTop: '10px' }}>
             {formState && formState.resumeP && !isResumeDeleted && (
              <Button
              onClick={handleDeleteResume}
              style={{
                display: 'block',
                height: '40%',
                width: 'auto',
                margin: '5px auto',
                fontSize: '2.5vmin',
                backgroundColor: 'rgb(200, 0, 0)',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                 }}
                >
                <h3 style={{ margin: '1%' }}>Delete Resume</h3>
                </Button>
                )}
                 
                {(!formState || !formState.resumeP || isResumeDeleted) && (
                <div style={{ marginTop: '10px' }}>
                  <Button
                    onClick={() => fileInputRefResume.current.click()}
                      style={{
                        display: 'block',
                        height: '40%',
                        width: 'auto',
                        margin: '5px auto',
                        fontSize: '2.5vmin',
                        backgroundColor: 'rgb(0, 128, 0)',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        }}
                        >
                        <input
                        ref={fileInputRefResume} // Assign the ref to the file input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        style={{ display: 'none' }}
                        onChange={handleResumeUpload} // Handle file change
                        />
                        <h3 style={{ margin: '1%' }}>Upload Resume</h3>
                   </Button>
                    </div>
                    )}
                </div>
            </div>


          </div>
            <div className="flex col-4">
              <label htmlFor="bio">
              <h4>
              bio 
              </h4>
              </label>
              <input
                placeholder="Tell us about yourself"
                name="bio"
                type="bio"
                id="bio"
                className="input-field"
                onChange={handleChange}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                }}
              />
            </div>

            <div>
              <div className="flex col-4">
                <label htmlFor="iMDB">
                  <h4>
                    iMDB
                  </h4>
                </label>
                <input
                  placeholder="iMDB Link"
                  name="iMDB"
                  type="text"
                  id="iMDB"
                  className="input-field"
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    marginBottom: '10px',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            </div>

          <div className="row justify-content-center">
          <div className="col-5">
            <label htmlFor="profileI">
              
            </label>
            <div  style={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '15px',
                  boxSizing: 'border-box',
                }}>
            {formState.profileI.length < 3 && (
            <>
              <input
                name="profileI"
                type="file"
                id="profileI"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                style={{
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                }}
              />
              
                <Button
                  style={{
                    display: 'block',
                    height: '40%',
                    width: 'auto',
                    margin: '5px auto',
                    fontSize: '2.5vmin',
                    backgroundColor: 'rgb(0, 128, 0)',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                >
                  <h3 style={{ margin: '1%' }}> + Add Profile Picture(s) </h3>
                </Button>
              
              </>
          )}
            </div>

            {formState.profileI.map((profileI, index) => (
              <div key={index} 
              style={{ 
                display: 'flex', 
                position: 'relative' }}>
                <img
                  src={
                    typeof profileI === 'string'
                      ? profileI
                      : URL.createObjectURL(profileI)
                  }
                  alt={`crew image ${index + 1}`}
                  className="img-fluid"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maskImage:
                      'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage:
                      'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                    display: 'flex',
                  }}
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    cursor: 'pointer',
                  }}
                >
                  ✕
                </button>
              </div>
            ))}

            {profilePreviews.length > 0 && (
              <div
                style={{
                  display: 'block',
                  marginTop: '10px',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                {profilePreviews.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ))}
              </div>
            )}
          </div>
          </div>
          {profileIError && <p style={{ color: 'white' }}>{profileIError}</p>}
        </div>
    <div>
      <div className="flex col-12"
      style={{
        width: '100%',
        maxWidth: '100%',
        marginBottom: '10px',
        boxSizing: 'border-box',
       }} >
      <label htmlFor="residence">
                <h4>Proof of Texas Residency</h4>
                <CardText>
                  In order to be listed on TFDirect, you must be a Texas resident
                  with supporting documentation. This documentation is used for verification
                  purposes only and will not be publicly available. Please DO NOT include 
                  documentation containing sensitive data such as a Social Security Number, 
                  financial account information, or the like.
                </CardText>
              </label>
      </div>  
      
    <div className="row justify-content-center">
        <div className='col-5'>
            {formState && formState.residence && !isResidenceDeleted && (
              <Button
              onClick={handleDeleteResidence}
              style={{
                display: 'block',
                height: '40%',
                width: 'auto',
                margin: '5px auto',
                fontSize: '2.5vmin',
                backgroundColor: 'rgb(200, 0, 0)',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                 }}
                >
                <h3 style={{ margin: '1%' }}>Delete Residency Doc</h3>
                </Button>
                )}
                 
                {(!formState || !formState.residence || isResidenceDeleted) && (
                <div style={{ marginTop: '10px' }}>
                  <Button
                    onClick={() => fileInputRefResidence.current.click()}  // Trigger file input click
                      style={{
                        display: 'block',
                        height: '40%',
                        width: 'auto',
                        margin: '5px auto',
                        fontSize: '2.5vmin',
                        backgroundColor: 'rgb(0, 128, 0)',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        }}
                        >
                        <input
                        ref={fileInputRefResidence}  
                        type="file"
                        accept=".pdf,.doc,.docx"
                        style={{ display: 'none' }}
                        onChange={handleResidenceUpload}
                        />
                        <h3 style={{ margin: '1%' }}>Upload Residency Doc</h3>
                   </Button>
                    </div>
                    )}
                    {residenceError && <p style={{ color: 'white' }}>{residenceError}</p>}
              </div>
             </div>
             
            </div>

        <div className="flex col-12">
          <div className="flex col-4">
            <label htmlFor="pwd">
            <h4>
                password 
              </h4>
            </label>
            <input
              placeholder="******"
              name="password"
              type="password"
              id="pwd"
              className="input-field"
              onChange={handleChange}
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '10px',
                boxSizing: 'border-box',
              }}
              />
               {passwordError && <p style={{ color: 'white' }}>{passwordError}</p>}
          </div>
        </div>
          
        <div className="flex-row flex-end">
          <Button type="submit" className="process-card-text hover-brighten"
          style={{
            position: 'relative',
            marginLeft: '6.5vmin',
            marginTop: '4.5vmin',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgb(67, 66, 66)',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
             <h4 style={{ margin: '1%' }}>
        submit
          </h4>
        </Button>
        </div>
        
        <CardText>
          Have an account? Login in 
          <Link to="/login" className="go-to-login-link"
          style={{
            color:'white',
            fontWeight: 'bold', 
            textDecoration:'none'
          }}> Here</Link>
        </CardText>
        </Card>
        
      </form>
    </div>
  );
}

export default Signup;
